import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        {/* <div className="row">
          <div className="col-lg-6">
            <div >
             
              <h1 className="" style={{ color: "#2EA0FD" }}>
                CAVE LAUNCHPAD
              </h1>
            </div>



          </div>
          <div className="col-lg-6">
           
              <h1 className="" style={{ color: "#2EA0FD",float:"right" }}>
                Get in Touch
              </h1>
            

          </div>
        </div> */}

        <div>
          <h1 style={{ color: "#2EA0FD" }}> CAVE LAUNCHPAD</h1>

          {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>

            <h1 style={{ color: "#2EA0FD" }}> Get in Touch</h1>

          </div> */}
        </div>

        <hr className="footer-hr" />

        <div className="d-lg-flex d-sm-block">
          <p>All Rights Reserved | CAVE LAUNCHPAD.</p>

          <div
            className="d-lg-flex d-sm-block"
            style={{
              justifyContent: "flex-end",
              margin: 0,
              padding: 0,
              flex: 1,
            }}
          >
            {/* <p>Get In touch contact@cavelaunchpad.com</p>&nbsp;&nbsp;&nbsp;&nbsp;
             */}

            <ul style={{ listStyle: "none", padding: 0 }}>
             
              <li>
                <a href="https://twitter.com/cavelaunchpad" target="_blank">
                  <svg
                    width="18"
                    height="15"
                    viewBox="0 0 18 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 1.77573C17.3386 2.07692 16.6265 2.28061 15.8796 2.37161C16.6424 1.90358 17.2277 1.16143 17.5034 0.277356C16.7892 0.710726 16 1.026 15.1569 1.19502C14.4849 0.459372 13.5246 0 12.4617 0C10.4237 0 8.77021 1.69556 8.77021 3.78657C8.77021 4.08342 8.8019 4.37161 8.86529 4.64897C5.79609 4.49079 3.07554 2.98483 1.25304 0.691224C0.935024 1.25244 0.753302 1.90358 0.753302 2.59697C0.753302 3.91008 1.40518 5.06934 2.3962 5.74865C1.79186 5.73023 1.22134 5.55796 0.722663 5.27627V5.32286C0.722663 7.15818 1.99577 8.68906 3.68621 9.03575C3.37665 9.12459 3.05018 9.16901 2.71315 9.16901C2.47544 9.16901 2.243 9.14626 2.01796 9.10184C2.48811 10.6056 3.85103 11.701 5.46751 11.7302C4.20391 12.7465 2.61067 13.3521 0.881141 13.3521C0.583201 13.3521 0.288431 13.3348 0 13.3001C1.63444 14.3727 3.57633 15 5.66086 15C12.4543 15 16.168 9.23077 16.168 4.22644C16.168 4.06176 16.1648 3.89707 16.1585 3.73564C16.8801 3.20152 17.5066 2.53521 18 1.77573Z"
                      fill="rgb(46, 160, 253)"
                    />
                  </svg>
                </a>
              </li>

              <li>
                <a href="https://web.facebook.com/profile.php?id=61551920851538" target="_blank">
                  <svg
                    style={{ color: "rgb(46, 160, 253)" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="rgb(46, 160, 253)"
                    class="bi bi-facebook"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path
                      d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
                      fill="rgb(46, 160, 253)"
                    ></path>{" "}
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://linkedin.com/company/cave-launchpad"
                  target="_blank"
                >
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.11877 3.77127H2.09337C0.823377 3.77127 0 2.94039 0 1.88717C0 0.813472 0.847719 0 2.14311 0C3.43744 0 4.2333 0.811424 4.2587 1.8841C4.2587 2.93731 3.43744 3.77127 2.11877 3.77127ZM18 16H14.0218V10.3754C14.0218 8.90312 13.3995 7.89806 12.031 7.89806C10.9844 7.89806 10.4023 8.57629 10.1313 9.22994C10.0419 9.43651 10.0435 9.71457 10.0452 10.0012V10.0012C10.0454 10.0401 10.0456 10.0791 10.0456 10.1182V16H6.10442C6.10442 16 6.15522 6.0365 6.10442 5.13082H10.0456V6.83665C10.2785 6.0908 11.5379 5.02632 13.5476 5.02632C16.041 5.02632 18 6.58974 18 9.95633V16ZM3.96237 5.13082H0.454022V16H3.96237V5.13082Z"
                      fill="rgb(46, 160, 253)"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a href="mailto:contact@cavelaunchpad.com">
                  <img width={20} src="/assets/images/email.png" alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* <div className="footer-down">
          <p>Copyright © 2023. CAVE LAUNCHPAD.</p>

          
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
