import React from "react";
import Header from "./Component/Header";
import { Provider } from 'react-redux';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Main from "./Component/Main";
import Footer from "./Component/Footer";
import store from "./store";
import Detail from "./Component/Detailpage/Detail";
import { createBrowserHistory } from 'history';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import Real from "./Component/real";


function App() {
  return (

    <Provider store={store}>
      
      <div className="bodymain">
      
        <div className="inner-main">
        <Header></Header>
          <BrowserRouter>


            <Switch>
              <Route exact path="/" component={Main} />
              <Route exact path="/detail" component={Detail} />
             



            </Switch>
          </BrowserRouter>
          <Footer />
        </div>
      </div>



    </Provider>

    //      <Provider store={store}>
    //       <div className="bodymain">
    //       <div className="inner-main">
    //         <Header></Header>

    //         <Routes>
    //           <Route exact path="/" element={<Main />} />
    //           <Route exact  path="/detail" element={<Detail />} />
    //         </Routes>

    //         <Footer />
    //       </div>
    //       </div>
    //       {/* <Header></Header>
    //       <Main></Main> */}
    //  </Provider>
  );
}

export default App;
