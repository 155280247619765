import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { Link, useHistory } from "react-router-dom";
import { contractAddresses } from "./ABI";
import { getTokenName } from "../blockchain";
import { config } from "../config";
const FilledCard = (props) => {
  const [tokenName, setTokenName] = useState(""); 
  const [logo, setLogo] = useState("");
 useEffect(() => {
    getDetail();
  }, []);


 
  const getDetail = () => {
    let dataString = props.item.preSale._imageurl;
    let dataArray = dataString.split(",");
    let title = dataArray.shift().trim();
    let email = dataArray.shift().trim();
    let launchDate = dataArray.shift().trim();
    let launchPlateform = dataArray.shift().trim();
    let urlStartIndex = dataArray.findIndex(
      (item) =>
        item.trim().startsWith("http://") ||
        item.trim().startsWith("https://") ||
        item.trim().startsWith("www.")
    );
    // Extract the description
    let description = dataArray.slice(0, urlStartIndex).join(",").trim();
    // Extract the URLs and other fields
    let urlsAndFields = dataArray.slice(urlStartIndex);
    setLogo(urlsAndFields[0].trim());
   
  };
 


  const history = useHistory()
  useEffect(() => {
    const fetchTokenName = async () => {
      // console.log("props.item.preSale._tokenAddress", props.item.preSale._tokenAddress)
      try {
        const name = await getErc20TokenName(props.item.preSale._tokenAddress);
        setTokenName(name);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchTokenName();
  }, [props.item.preSale._tokenAddress]);

  const getErc20TokenName = async (tokenAddress) => {
    try {
      const { name } = await getTokenName(props.provider, tokenAddress);
      return name;
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleLinkClick = (item) => {
    // console.log("pros", navigate, item)
    history.push("/detail", { data: item });
    // navigate(0)
  };




  return (
    <div>
      {console.log("items", props.item)}
      <div className="nft-cards">
        <div className="d-flex justify-content-between align-items-center pb-4  pb-md-1">
          <div className="d-flex justify-content-between align-items-center">
            <img
              style={{ width: "58px" }}
              src={logo}
              alt="filled"
            />
            <div style={{ marginLeft: "5px" }}>
              <h6 style={{ fontSize: "15px", margin: "0px" }}>{tokenName}</h6>

            </div>
          </div>
          <div className="filled d-flex justify-content-between align-items-center">
            <span>
              <img
                className=""
                style={{ marginRight: "5px" }}
                src="assets/images/icons/ellipse.png"
                alt="ellipse"
              />
            </span>
            <p style={{ fontSize: "13px" }}>Closed</p>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-6">
            <label style={{ fontSize: "13.12px" }}>Launch Price</label>
            <button
              className="c-btn"
              style={{ borderRadius: "7px", fontSize: "13.12px" }}
            >
             
              {tokenName && (
                <>
                  {tokenName} = {props.item.preSale.price / contractAddresses.usdtDecimelIndex} USDT
                </>
              )}

            </button>
          </div>
          <div className="col-lg-6">
            <label style={{ fontSize: "13.12px" }}>Pre-Launch Price</label>
            <button
              className="c-btn"
              style={{ borderRadius: "7px", fontSize: "13.12px" }}
            >
              {tokenName && (
                <>
                  {tokenName} = {props.item.preSale._prelaunchprice / contractAddresses.usdtDecimelIndex} USDT
                </>
              )}
            </button>
          </div>
        </div> */}

        <div className="row  mt-3 mt-md-1">
          <div className="col-lg-12">
            <label style={{ fontSize: "13.12px" }}>Pre-Launch Price </label>
            <button
              className="b-btn "
              style={{ borderRadius: "7px", fontSize: "13.12px" }}
            >
              {tokenName && (
                <>
                  {tokenName} = {1/parseFloat(props.item.preSale._prelaunchprice / config.tokenBalanceDecimel)} USDT
                </>
              )}
            </button>
          </div>
        </div>

        <div className="row  mt-3 mt-md-1">
          <div className="col-lg-12">
            <label style={{ fontSize: "13.12px" }}>Launching Price </label>
            <button
              className="b-btn "
              style={{ borderRadius: "7px", fontSize: "13.12px" }}
            >
              {tokenName && (
                <>
                  {tokenName} = {1/parseFloat(props.item.preSale.price / config.tokenBalanceDecimel)} USDT
                </>
              )}
            </button>
          </div>
        </div>



        <div className="align-items-center d-flex justify-content-between pt-3">
          <div>
            <h6 style={{ margin: "0px" }}>Sale Started At:</h6>
            <p style={{ fontSize: "12px" }}>{new Date(props.item.preSale._startTime * 1000).toLocaleString()}</p>
          </div>
          <div>
            <h6 style={{ margin: "0px" }}>Sale Ended At:</h6>
            <p style={{ fontSize: "12px" }}>{new Date(props.item.preSale._endTime * 1000).toLocaleString()}</p>
          </div>
        </div>

        <hr />
        <div className="d-flex justify-content-between align-items-center ">
          <div className="d-flex align-items-center">
            <p>Network:</p>
            <img
              style={{ width: "30px", marginLeft: "5px" }}
              src="assets/images/icons/coinbsc.png"
              alt=""
            />
          </div>

          <div>

            <Link onClick={() => handleLinkClick(props.item)} className="m-btn">
              view
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

// export default FilledCard;

export default React.memo(FilledCard);
