// import { ethers } from "ethers";
// import { useEffect, useState } from "react";
// import React from "react";
// import {
//   connectMetamask1,
//   getBNBBalance,
//   getTokenBalance,
// } from "../blockchain";
// import { config } from "../config";
// import { isEmpty } from "lodash";
// import { connect } from "react-redux";

// import Swal from "sweetalert";
// import { contractAddresses } from "../utils/ABI";
// import store from "../store";
// import { useSelector, useDispatch } from "react-redux";

// import {
//   setNetworkID,
//   setWallet,
//   setMetamaskConnection,
//   saveMetamaskProvider,
//   setUserWalletUsdtBalance,
//   setUserWalletBNBBalance,
// } from "../redux/action/WalletAction";
// import { saveProvider } from "../redux/action/WalletAction";
// const Header = (props) => {
//   const [walletAddress, setWalletAddress] = useState("");
//   const [walletBalance, setWalletBalance] = useState(0);
//   const [myProvider, setMyProvider] = useState("");
//   const [currentAccount, setCurrentAccount] = useState("");
//   const [currentNetwork, setCurrentNetwork] = useState("");
//   const [tokenName, setTokenName] = useState("");
//   const [tokenSymbol, setTokenSymbol] = useState("");
//   const [tokenBalance, setTokenBalance] = useState(0);
//   const [isConnected, setIsConnected] = useState(false);
//   // const provider = new ethers.providers.Web3Provider(window.ethereum);

//   useEffect(() => {

//     // Setup provider
//     const provider = new ethers.providers.JsonRpcProvider(config.rpcUrl);
//     store.dispatch(saveProvider(provider));
//   }, []);

//   useEffect(() => {
//     // Check if MetaMask is available and connected
//     if (typeof window.ethereum !== "undefined") {
//       console.log("Inside 1");

//       // Request access to the user's MetaMask accounts
//       window.ethereum
//         .request({ method: "eth_accounts" })
//         .then((accounts) => {
//           console.log("Inside 2", accounts);
//           if (accounts.length > 0) {
//             console.log("Inside 2 ");

//             const ethProvider = new ethers.providers.Web3Provider(
//               window.ethereum
//             );
//             ethProvider.getNetwork().then((network) => {
//               console.log("Networ", network.chainId);
//               if (network.chainId.toString() === config.networkID) {
//                 store.dispatch(setNetworkID(network.chainId));
//                 store.dispatch(setWallet(accounts[0]));
//                 store.dispatch(saveMetamaskProvider(ethProvider));
//                 store.dispatch(setMetamaskConnection(true));
//                 store.dispatch(
//                   setUserWalletUsdtBalance(
//                     config.usdtAddress,
//                     accounts[0],
//                     ethProvider
//                   )
//                 );
//                 store.dispatch(
//                   setUserWalletBNBBalance(accounts[0], ethProvider)
//                 );
//               } else {
//                 store.dispatch(setMetamaskConnection(true));
//               }
//             });
//           } else {
//             // alert("not connected")
//             // setIsMetaMaskConnected(false);
//           }
//         })
//         .catch((err) => {
//           console.log("Inside 3", err);
//           console.error("Error checking MetaMask connection:", err);
//         });
//     }
//   }, []);

//   useEffect(() => {
//     if (window.ethereum) {
//       window.ethereum.on("chainChanged", (newNetworkId) => {
//         window.location.reload();
//       });
//       window.ethereum.on("accountsChanged", (accounts) => {
//         window.location.reload();
//       });
//     }
//   }, []);

//   return (
//     <>
//       <div className="pt-4 pb-4">
//         {/* //{console.log("dddd", walletAddress)} */}
//         <div className="container">
//           <div className="row">
//             <div className="col-lg-12">
//               <div className="d-flex flex-wrap justify-content-between align-items-center p-15">
//                 <div className="d-flex align-items-center">
//                   <img
//                     style={{ borderRadius: 50 }}
//                     className="logo"
//                     src="/assets/images/logoabc.jpeg"
//                     alt="launchpad"
//                   />
//                   <h1 className="heading-main" style={{ color: "#2EA0FD" }}>
//                     CAVE LAUNCHPAD
//                   </h1>
//                 </div>
//                 {props.walletReducer.metamaskConnected === true &&
//                 props.walletReducer.networkID.toString() ===
//                   config.networkID ? (
//                   <div className="d-flex flex-wrap align-items-center">
//                     {/* <button className="s-btn">
//                         {" "}
//                         <img src="assets/images/bsc.png" alt="" /> {props.walletReducer.userWalletBNBBalance.toFixed(4)} {config.currencyName}
//                       </button> */}
//                     {/* <button className="t-btn">
//                         {" "}
//                         <img
//                           src="assets/images/icons/tokenbalnce.png"
//                           alt=""
//                         />{" "}
//                         {(props.walletReducer.userWalletUsdtBalance / config.usdtTokenDecimel).toFixed(2)} USDT

//                       </button> */}
//                     {/* <button className="h-btn">
//                         {" "}
//                         <img src="assets/images/bsc.png" alt="" /> {config.networkName} MAINNET
//                       </button> */}
//                     <div className="d-flex align-items-center group">
//                       <button className="m-btn">
//                         <div
//                           style={{ marginRight: 24, width: 200 }}
//                           className="d-flex align-items-center inner-btn"
//                         >
//                           <img src="assets/images/metamask.png" alt="" /> &nbsp;
//                           Connected
//                         </div>
//                       </button>
//                       {props.walletReducer.connectedWallet && (
//                         <button
//                           style={{ position: "relative", right: 100 }}
//                           className="h-btn"
//                         >
//                           {props.walletReducer.connectedWallet.substring(0, 6) +
//                             "..." +
//                             props.walletReducer.connectedWallet.slice(-6)}
//                         </button>
//                       )}
//                     </div>

//                     {/* <button className="h-btn" onClick={createTxn}>
//                     Mytxn
//                   </button> */}
//                     {/* <div>
//                     <p>Walletaddress: {walletAddress}</p>
//                     <p>WalletBalance: {walletBalance}</p>
//                     <p>TokenName: {tokenName}</p>
//                     <p>TokenSymbol: {tokenSymbol}</p>
//                     <p>TokenBalance: {balanceTokens}</p>
//                   </div> */}
//                   </div>
//                 ) : props.walletReducer.metamaskConnected === true ? (
//                   <div className="d-flex align-items-center group">
//                     <button className="m-btn">
//                       <div
//                         style={{ marginRight: 24, width: 200 }}
//                         className="d-flex align-items-center inner-btn"
//                       >
//                         <img src="assets/images/metamask.png" alt="" /> &nbsp;
//                         Connected
//                       </div>
//                     </button>
//                     {props.walletReducer.connectedWallet && (
//                       <button
//                         style={{ position: "relative", right: 100 }}
//                         className="h-btn"
//                       >
//                         {props.walletReducer.connectedWallet.substring(0, 6) +
//                           "..." +
//                           props.walletReducer.connectedWallet.slice(-6)}
//                       </button>
//                     )}
//                   </div>
//                 ) : null}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// const mapStateToProps = (state) => ({
//   walletReducer: state.WalletReducers,
// });

// export default connect(mapStateToProps, {})(Header);



import { ethers } from "ethers";
import { useEffect, useState } from "react";
import React from "react";
import {
  connectMetamask1,
  getBNBBalance,
  getTokenBalance,
} from "../blockchain";
import { config } from "../config";
import { isEmpty } from "lodash";
import { connect } from "react-redux";

import Swal from 'sweetalert';
import { contractAddresses } from "../utils/ABI";
import store from "../store";
import { useSelector, useDispatch } from 'react-redux';

import { setNetworkID, setWallet, setMetamaskConnection, saveMetamaskProvider, setUserWalletUsdtBalance, setUserWalletBNBBalance } from "../redux/action/WalletAction";
import { saveProvider } from "../redux/action/WalletAction";
const Header = (props) => {
  const [walletAddress, setWalletAddress] = useState("");
  const [walletBalance, setWalletBalance] = useState(0);
  const [myProvider, setMyProvider] = useState("");
  const [currentAccount, setCurrentAccount] = useState("");
  const [currentNetwork, setCurrentNetwork] = useState("");
  const [tokenName, setTokenName] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [tokenBalance, setTokenBalance] = useState(0);
  const [isConnected, setIsConnected] = useState(false);
  const [localAccount, setLocalAccount] = useState("");
  // const provider = new ethers.providers.Web3Provider(window.ethereum);



  useEffect(() => {
    // Setup provider
    const provider = new ethers.providers.JsonRpcProvider(config.rpcUrl);
    store.dispatch(saveProvider(provider))

  }, []);



  useEffect(() => {
    // Check if MetaMask is available and connected
    if (typeof window.ethereum !== 'undefined') {
      console.log("Inside 1")

      // Request access to the user's MetaMask accounts
      window.ethereum
        .request({ method: 'eth_accounts' })
        .then(accounts => {
          console.log("Inside 2", accounts)
          if (accounts.length > 0) {
            console.log("Inside 2 ")

            const ethProvider = new ethers.providers.Web3Provider(window.ethereum);
            ethProvider.getNetwork().then(network => {
              console.log("Networ", network.chainId)
              if (network.chainId.toString() === config.networkID) {


                store.dispatch(setNetworkID(network.chainId))
                store.dispatch(setWallet(accounts[0]))
                store.dispatch(saveMetamaskProvider(ethProvider))
                store.dispatch(setMetamaskConnection(true))
                store.dispatch(setUserWalletUsdtBalance(config.usdtAddress, accounts[0], ethProvider))
                store.dispatch(setUserWalletBNBBalance(accounts[0], ethProvider))


              }
              else {
                console.log(" accounts[0]", accounts[0])
                setLocalAccount(accounts[0])
                store.dispatch(setMetamaskConnection(true))
              }
            })
          } else {
            // alert("not connected")
            // setIsMetaMaskConnected(false);
          }
        })
        .catch(err => {
          console.log("Inside 3", err)
          console.error('Error checking MetaMask connection:', err);
        });
    }
  }, []);


  useEffect(() => {
    if (window.ethereum) {

      window.ethereum.on("chainChanged", (newNetworkId) => {
        window.location.reload();

      });
      window.ethereum.on("accountsChanged", (accounts) => {
        window.location.reload();

      });
    }
  }, []);
















  return (
    <>

      <div className="pt-4 pb-4">
        {/* //{console.log("dddd", walletAddress)} */}
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex flex-wrap justify-content-between align-items-center p-15">
                <div className="d-flex align-items-center">
                  <img style={{ borderRadius: "50%" }} className="logo" src="/assets/images/logoabc.jpeg" alt="launchpad" />
                  <h1 className="heading-main" style={{ color: "#2EA0FD" }}>
                    CAVE LAUNCHPAD
                  </h1>
                </div>
                {
                  props.walletReducer.metamaskConnected === true && props.walletReducer.networkID.toString() === config.networkID ?

                    <div className="d-flex flex-wrap align-items-center">
                      <button className="s-btn">
                        {" "}
                        <img src="assets/images/bsc.png" alt="" /> {props.walletReducer.userWalletBNBBalance.toFixed(4)} {config.currencyName}
                      </button>
                      <button className="t-btn">
                        {" "}
                        <img
                          src="assets/images/icons/tokenbalnce.png"
                          alt=""
                        />{" "}
                        {(props.walletReducer.userWalletUsdtBalance / config.usdtTokenDecimel).toFixed(2)} USDT

                      </button>
                      {/* <button className="h-btn">
                        {" "}
                        <img src="assets/images/bsc.png" alt="" /> {config.networkName} MAINNET
                      </button> */}
                      <div className="d-flex align-items-center group">


                        <button className="m-btn">

                          <div style={{ marginRight: 24, width: 200 }} className="d-flex align-items-center inner-btn">
                            <img src="assets/images/metamask.png" alt="" />{" "}&nbsp;
                            Connected
                          </div>

                        </button>
                        {
                          props.walletReducer.connectedWallet && <button style={{ position: "relative", right: 100 }} className="h-btn">
                            {props.walletReducer.connectedWallet.substring(0, 6) + "..." + props.walletReducer.connectedWallet.slice(-6)}
                          </button>
                        }

                      </div>

                      {/* <button className="h-btn" onClick={createTxn}>
                    Mytxn
                  </button> */}
                      {/* <div>
                    <p>Walletaddress: {walletAddress}</p>
                    <p>WalletBalance: {walletBalance}</p>
                    <p>TokenName: {tokenName}</p>
                    <p>TokenSymbol: {tokenSymbol}</p>
                    <p>TokenBalance: {balanceTokens}</p>
                  </div> */}
                    </div> : props.walletReducer.metamaskConnected === true ?
                    //  <button className="m-btn">

                    //   <div style={{ padding: 8 }} className="d-flex align-items-center ">
                    //     <img src="assets/images/metamask.png" alt="" />{" "}&nbsp;
                    //     Connected
                    //   </div>

                    // </button>

                    <div className="d-flex align-items-center group">


                    <button className="m-btn">

                      <div style={{ marginRight: 24, width: 200 }} className="d-flex align-items-center inner-btn">
                        <img src="assets/images/metamask.png" alt="" />{" "}&nbsp;
                        Connected
                      </div>

                    </button>
                    {
                      localAccount && <button style={{ position: "relative", right: 100 }} className="h-btn">
                        {localAccount.substring(0, 6) + "..." + localAccount.slice(-6)}
                      </button>
                    }

                  </div>
                    
                    
                    
                    
                    :





                      null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({

  walletReducer: state.WalletReducers,
})

export default connect(mapStateToProps, {})(Header)
