
  export const launchPadABI = [
    "function totalPresale()  view returns(uint256)",
    "function getPresaleDetail(uint256 _counter) view returns(address ,address ,string memory,uint256 ,uint256 ,uint256 ,uint256 ,uint256 ,uint256 ,uint256 ,uint256 ,uint256 )",
    "function investWithUSDT(uint256 _count, uint256 _amount) returns(uint256)",
  
  ]

  export const erc20ABI = [
    // Some details about the token
    "function name() view returns (string)",
    "function symbol() view returns (string)",
    "function totalSupply() view returns (unit256)",
    "function symbol() view returns (unit8)",

    // Get the account balance
    "function balanceOf(address) view returns (uint256)",
    "function approve(address spender, uint tokens) public returns (bool success)",
    // Send some of your tokens to someone else
    "function transfer(address to, uint amount)",

    // An event triggered whenever anyone transfers to someone else
    "event Transfer(address indexed from, address indexed to, uint amount)",
  ];

  export const contractAddresses = {
    usdtAddress: '0xa00d3aA58758628FA3a0a7b7E469bA92329F6f4f',
    ownerAddress:'',
    launchPadAddress:'0xe0be0FC53f6267D39BEF7279ae3995c6F080457C',
    usdtDecimelIndex:1e6,
    tokenDecimel:1e18
  }

