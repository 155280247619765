import React, { useEffect, useState } from "react";
import Header from "../Header";
import Timmer from "./Timmer";
import Images from "./Images";
import NetworkDetails from "./NetworkDetails";
import Faqs from "./Faqs";
import Footer from "../Footer";
import { config } from "../../config";
import { Link } from "react-router-dom";

const Detail = (props) => {
  const [title, setTitle] = useState("");
  const [des, setDesc] = useState("");
  const [twitter, setTwitter] = useState("");
  const [telegram, setTelegram] = useState("");
  const [website, setWebsite] = useState("");
  const [logo, setLogo] = useState("");
  const [launchDate, setLaunchDate] = useState("");
  const [launchPlateform, setLaunchPlateform] = useState("");
  const [prelauanchPrice, setPrelauanchPrice] = useState("");
  const [price, setPrice] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  useEffect(() => {
    getDetail(props.location.state.data);
  }, []);

  const getDetail = (saleObject) => {
    let dataString = saleObject.preSale._imageurl;
    console.log(
      "dataString",
      saleObject.preSale._imageurl,
      props.location.state.data.preSale
    );

    let dataArray = dataString.split(",");
    let title = dataArray.shift().trim();
    let email = dataArray.shift().trim();
    let launchDate = dataArray.shift().trim();
    let launchPlateform = dataArray.shift().trim();
    setTitle(title);
    setTelegram(email);
    setLaunchDate(launchDate);
    setLaunchPlateform(launchPlateform);

    let urlStartIndex = dataArray.findIndex(
      (item) =>
        item.trim().startsWith("http://") ||
        item.trim().startsWith("https://") ||
        item.trim().startsWith("www.")
    );
    // Extract the description
    let description = dataArray.slice(0, urlStartIndex).join(",").trim();
    // Extract the URLs and other fields
    let urlsAndFields = dataArray.slice(urlStartIndex);

    setDesc(description);
    // console.log(urlsAndFields);
    setLogo(urlsAndFields[0].trim());
    setWebsite(urlsAndFields[1].trim());
    setTwitter(urlsAndFields[2].trim());
    setPrelauanchPrice(props.location.state.data.preSale._prelaunchprice);
    setPrice(props.location.state.data.preSale.price);
    setStartTime(props.location.state.data.preSale._startTime);
    setEndTime(props.location.state.data.preSale._endTime);
  };

  return (
    <div className="">
      <div className="container responsive">
        <Link to="/" className="d-flex align-items-center ">
          <svg
            width="13"
            height="21"
            viewBox="0 0 13 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginRight: "10px" }}
          >
            <path
              d="M12.351 17.8253L10.0394 20.137L0.176371 10.274L10.0394 0.410957L12.351 2.7226L4.79966 10.274L12.351 17.8253Z"
              fill="white"
            />
          </svg>

          <p style={{ color: "white" }}>Back to Home Page </p>
        </Link>
        <div className="row pb-5 pt-5">
          {/* <div className="col-lg-12">
            <div className="d-flex align-items-center justify-content-between pt-2 pb-2">
              <div className="d-flex align-items-center">
                <a style={{textDecoration:"none" ,color:"black"}} className="d-flex align-items-center" href="/">
                <svg
                  width="13"
                  height="21"
                  viewBox="0 0 13 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    marginRight: "10px",
                  }}
                >
                  <path
                    d="M12.351 17.8253L10.0394 20.137L0.176371 10.274L10.0394 0.410957L12.351 2.7226L4.79966 10.274L12.351 17.8253Z"
                    fill="black"
                  />
                </svg>
                <h6>Back to Offers</h6>
                </a>
              </div>
              <div>
                <div className="download">
                  <img src="assets/images/icons/download.png" alt="download" />
                </div>
                <p style={{ color: "#DF5E5E" }}>Download PDF</p>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between pt-3 pb-3 ">
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src="assets/images/icons/token-details.png"
                    alt="token-details"
                    style={{ marginRight: "10px" }}
                  />
                </div>
                <div>
                  <h1>AntiSEC Fair Launch</h1>
                  <svg
                    width="34"
                    height="33"
                    viewBox="0 0 34 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: "20px" }}
                  >
                    <path
                      d="M16.7921 29.4647C24.3326 29.4647 30.4454 23.6467 30.4454 16.4697C30.4454 9.2927 24.3326 3.47461 16.7921 3.47461C9.2515 3.47461 3.13867 9.2927 3.13867 16.4697C3.13867 23.6467 9.2515 29.4647 16.7921 29.4647Z"
                      stroke="black"
                      stroke-width="1.55941"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.13867 16.4697H30.4454"
                      stroke="black"
                      stroke-width="1.55941"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.7914 3.47461C20.2065 7.03312 22.1473 11.6511 22.2528 16.4697C22.1473 21.2882 20.2065 25.9062 16.7914 29.4647C13.3763 25.9062 11.4355 21.2882 11.3301 16.4697C11.4355 11.6511 13.3763 7.03312 16.7914 3.47461Z"
                      stroke="black"
                      stroke-width="1.55941"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                    width="33"
                    height="26"
                    viewBox="0 0 33 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: "20px" }}
                  >
                    <path
                      d="M31.7972 1.77283C30.4897 2.65062 29.0421 3.32199 27.51 3.76108C26.6878 2.86118 25.5949 2.22336 24.3794 1.93388C23.1638 1.64439 21.8842 1.71721 20.7135 2.14248C19.5428 2.56776 18.5376 3.32497 17.8339 4.3117C17.1301 5.29844 16.7617 6.46709 16.7785 7.6596V8.9591C14.3791 9.01832 12.0015 8.51183 9.8576 7.48474C7.71365 6.45766 5.86987 4.94186 4.49044 3.07234C4.49044 3.07234 -0.970911 14.7679 11.3171 19.9659C8.50526 21.7826 5.15558 22.6935 1.75977 22.5649C14.0478 29.0625 29.0665 22.5649 29.0665 7.62061C29.0653 7.25864 29.0287 6.89756 28.9573 6.54202C30.3508 5.23406 31.3341 3.58267 31.7972 1.77283Z"
                      stroke="black"
                      stroke-width="1.55941"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                    width="30"
                    height="24"
                    viewBox="0 0 30 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: "20px" }}
                  >
                    <path
                      d="M29.0097 2.88116L24.6833 22.3007C24.3569 23.6712 23.5057 24.0124 22.2961 23.3667L15.7041 18.7433L12.5233 21.655C12.1713 21.99 11.8769 22.2702 11.1985 22.2702L11.6721 15.8803L23.8897 5.37256C24.4209 4.92179 23.7745 4.67204 23.0641 5.12281L7.96006 14.1747L1.45763 12.2376C0.0432286 11.8173 0.0176284 10.8914 1.75203 10.2457L27.1857 0.919717C28.3633 0.499408 29.3937 1.16947 29.0097 2.88116Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <img
                  className="h-btn"
                  src="assets/images/icons/fav.png"
                  alt="fav"
                  style={{ marginRight: "5px" }}
                />
                <div className="up-coming d-flex justify-content-between align-items-center">
                  <span>
                    <img
                      className=""
                      style={{ marginRight: "5px" }}
                      src="assets/images/icons/ellipse.png"
                      alt="ellipse"
                    />
                  </span>
                  <p style={{ fontSize: "13px" }}>UpComing</p>
                </div>
              </div>
            </div>
            <Timmer />
            <Images />
            <NetworkDetails />
            <Faqs />
          </div> */}
          <div className="col-lg-7">
            <div className="d-flex align-items-center">
              <div>
                <img
                  src={logo}
                  alt="token-details"
                  className="v-tag"
                  style={{ marginRight: "10px", width: 94, height: 94 }}
                />
              </div>
              <div>
                <h1 className="text-white " style={{ fontSize: "25px" }}>
                  {title}
                </h1>
                <a target="_blank" href={website}>
                  <svg
                    width="26"
                    height="25"
                    viewBox="0 0 26 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: "20px" }}
                  >
                    <path
                      d="M13.2546 22.1036C19.0788 22.1036 23.8003 17.6098 23.8003 12.0665C23.8003 6.52309 19.0788 2.0293 13.2546 2.0293C7.43042 2.0293 2.70898 6.52309 2.70898 12.0665C2.70898 17.6098 7.43042 22.1036 13.2546 22.1036Z"
                      stroke="rgb(46, 160, 253)"
                      stroke-width="1.20446"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.70898 12.0645H23.8003"
                      stroke="rgb(46, 160, 253)"
                      stroke-width="1.20446"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.2554 2.0293C15.8931 4.77783 17.3922 8.34471 17.4736 12.0665C17.3922 15.7882 15.8931 19.3551 13.2554 22.1036C10.6176 19.3551 9.11857 15.7882 9.03711 12.0665C9.11857 8.34471 10.6176 4.77783 13.2554 2.0293Z"
                      stroke="rgb(46, 160, 253)"
                      stroke-width="1.20446"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <a target="_blank" href={twitter}>
                  <svg
                    width="26"
                    height="25"
                    viewBox="0 0 26 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: "20px" }}
                  >
                    <g clip-path="url(#clip0_2654_18191)">
                      <path
                        d="M24.4152 3.03158C23.4054 3.70957 22.2872 4.22812 21.1039 4.56726C20.4688 3.8722 19.6247 3.37956 18.6858 3.15596C17.747 2.93237 16.7586 2.98861 15.8544 3.31709C14.9502 3.64556 14.1738 4.23042 13.6302 4.99255C13.0866 5.75469 12.8021 6.65734 12.815 7.57841V8.58213C10.9618 8.62787 9.12541 8.23666 7.46947 7.44336C5.81352 6.65005 4.38941 5.47928 3.32397 4.03529C3.32397 4.03529 -0.894283 13.0687 8.59679 17.0836C6.42494 18.4868 3.83771 19.1903 1.21484 19.091C10.7059 24.1096 22.3061 19.091 22.3061 7.5483C22.3051 7.26872 22.2769 6.98983 22.2217 6.71522C23.298 5.70497 24.0576 4.42947 24.4152 3.03158Z"
                        stroke="rgb(46, 160, 253)"
                        stroke-width="1.20446"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2654_18191">
                        <rect
                          width="25.3095"
                          height="24.0892"
                          fill="white"
                          transform="translate(0.15918 0.0214844)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </a>

                <a target="_blank" href={`mailto:${telegram}`}>
                  {/* <svg
                        width="27"
                        height="25"
                        viewBox="0 0 27 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginRight: "20px" }}
                      >
                        <path
                          d="M24.3786 4.66184L21.037 19.6611C20.7849 20.7197 20.1274 20.9832 19.1931 20.4845L14.1016 16.9135L11.6448 19.1624C11.3729 19.4212 11.1455 19.6376 10.6215 19.6376L10.9873 14.7021L20.424 6.58616C20.8343 6.23799 20.335 6.04509 19.7863 6.39325L8.12021 13.3848L3.09785 11.8886C2.00539 11.564 1.98562 10.8488 3.32524 10.3501L22.9698 3.14686C23.8793 2.82222 24.6752 3.33976 24.3786 4.66184Z"
                          fill="#F7F7F7"
                        />
                      </svg> */}
                  <svg
                    width="27"
                    height="25"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 64 64"
                    id="email"
                  >
                    <path
                      fill="rgb(46, 160, 253)"
                      d="M53.42 53.32H10.58a8.51 8.51 0 0 1-8.5-8.5V19.18a8.51 8.51 0 0 1 8.5-8.5h42.84a8.51 8.51 0 0 1 8.5 8.5v25.64a8.51 8.51 0 0 1-8.5 8.5ZM10.58 13.68a5.5 5.5 0 0 0-5.5 5.5v25.64a5.5 5.5 0 0 0 5.5 5.5h42.84a5.5 5.5 0 0 0 5.5-5.5V19.18a5.5 5.5 0 0 0-5.5-5.5Z"
                    ></path>
                    <path
                      fill="rgb(46, 160, 253)"
                      d="M32 38.08a8.51 8.51 0 0 1-5.13-1.71L3.52 18.71a1.5 1.5 0 1 1 1.81-2.39L28.68 34a5.55 5.55 0 0 0 6.64 0l23.35-17.68a1.5 1.5 0 1 1 1.81 2.39L37.13 36.37A8.51 8.51 0 0 1 32 38.08Z"
                    ></path>
                    <path
                      fill="rgb(46, 160, 253)"
                      d="M4.17 49.14a1.5 1.5 0 0 1-1-2.62l18.4-16.41a1.5 1.5 0 0 1 2 2.24L5.17 48.76a1.46 1.46 0 0 1-1 .38zm55.66 0a1.46 1.46 0 0 1-1-.38l-18.4-16.41a1.5 1.5 0 1 1 2-2.24l18.39 16.41a1.5 1.5 0 0 1-1 2.62z"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <p
              className="text-align-justify pt-4 pb-4"
              style={{
                textAlign: "justify",
                letterSpacing: "3%",
                color: "white",
                lineHeight: "43.47px",
              }}
            >
              {des}
            </p>
          </div>

          <div className="row pb-5">
            <div className="col-lg-12">
              <div className="network-main">
                <div className="d-flex justify-content-between align-items-center">
                  {/* <div><h2 style={{ color: "white" }}>Apply for Launchpad</h2></div> */}
                </div>

                <div className="inner-network mt-3">
                  <div className="row">
                    <div
                      className="col-lg-4 col-md-12"
                      style={{ position: "relative" }}
                    >
                      <div className="d-flex align-items-center net">
                        <img
                          style={{ width: "65px", marginRight: "10px" }}
                          src="assets/images/bnb.png"
                          alt="bsc"
                        />
                        <h2>Token Details</h2>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-12">
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-4">
                          <p>Pre-Launch Price</p>
                          <h4>
                            {1 /
                              (parseFloat(prelauanchPrice) /
                                config.tokenBalanceDecimel)}{" "}
                            USDT
                          </h4>
                        </div>

                        <div className="col-lg-4 col-md-4 col-4">
                          <p>Launching Price</p>
                          <h4>
                            {1 /
                              (parseFloat(price) /
                                config.tokenBalanceDecimel)}{" "}
                            USDT
                          </h4>
                        </div>
                        <div className="col-lg-4 col-md-4 col-4">
                          <p>Launch On</p>
                          <h4 style={{ wordBreak: "break-all" }}>
                            {/* {currentSaleObject && currentSaleObject.preSale && parseFloat(currentSaleObject.preSale._remainingAmount) /
                                config.tokenBalanceDecimel} */}

                            <a
                              target="_blank"
                              style={{ color: "white" }}
                              href={launchPlateform}
                            >
                              {launchPlateform}
                            </a>
                          </h4>
                        </div>
                      </div>
                      <hr />
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-4">
                            <p>Sale Started At</p>
                            <h4>
                              {new Date(startTime * 1000).toLocaleString()}
                            </h4>
                          </div>
                          <div className="col-lg-4 col-md-4 col-4">
                            <p>Sale Ended At</p>

                            <h4>{new Date(endTime * 1000).toLocaleString()}</h4>
                          </div>
                          <div className="col-lg-4 col-md-4 col-4">
                            <p>Launch At</p>
                            <h4>
                              {new Date(launchDate * 1000).toLocaleString()}
                            </h4>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-12">
                            <div className="d-flex align-items-center ">
                              <p className="d-flex align-items-center">
                                Token Contract Address : {""}{" "}
                                <p style={{ wordBreak: "break-all" }}>
                                  {""}
                                  {
                                    props.location.state.data.preSale
                                      ._tokenAddress
                                  }
                                </p>
                              </p>
                            </div>
                            {/* <h4>
                              {new Date(startTime * 1000).toLocaleString()}
                            </h4> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-12">


            <div className="pricing">
              <div className="row">

                <div className="col-lg-4 col-md-4  col-sm-4  col-4">
                  <h1
                    style={{
                      fontSize: "11px",
                      color: "rgba(38, 164, 219, 1)",
                      paddingBottom: "10px",
                    }}
                  >
                    Current Price
                  </h1>

                  <p style={{ fontSize: "13px", color: "white" }}>{parseFloat(prelauanchPrice) /
                    config.tokenBalanceDecimel} USDT</p>
                </div>
                <div className="col-lg-4 col-md-4  col-sm-4  col-4">
                  <h1
                    style={{
                      fontSize: "11px",
                      color: "rgba(38, 164, 219, 1)",
                      paddingBottom: "10px",
                    }}
                  >
                    Launching Price
                  </h1>
                  <p style={{ fontSize: "13px", color: "white" }}>{parseFloat(price) /
                    config.tokenBalanceDecimel} USDT</p>
                </div>
                <div className="col-lg-4 col-md-4  col-sm-4  col-4">
                  <h1
                    style={{
                      fontSize: "11px",
                      color: "rgba(38, 164, 219, 1)",
                      paddingBottom: "10px",
                    }}
                  >
                    Lauch Platform
                  </h1>
                  <p style={{ fontSize: "13px", color: "white" }}><a target="_blank" style={{ color: "white" }} href={launchPlateform}>{launchPlateform}</a></p>
                </div>
                <div style={{ marginTop: 16 }} className="col-lg-4 col-md-4  col-sm-4  col-4">
                  <h1
                    style={{
                      fontSize: "11px",
                      color: "rgba(38, 164, 219, 1)",
                      paddingBottom: "10px",
                    }}
                  >
                    Sale Started At
                  </h1>
                
                  <p style={{ fontSize: "13px", color: "white" }}>{new Date(startTime * 1000).toLocaleString()}</p>







                </div>

                <div style={{ marginTop: 16 }} className="col-lg-4 col-md-4  col-sm-4  col-4">
                  <h1
                    style={{
                      fontSize: "11px",
                      color: "rgba(38, 164, 219, 1)",
                      paddingBottom: "10px",
                    }}
                  >
                    Sale Ended At
                  </h1>
                  <p style={{ fontSize: "13px", color: "white" }}>{new Date(endTime * 1000).toLocaleString()}</p>
                </div>

                <div style={{ marginTop: 16 }} className="col-lg-4 col-md-4  col-sm-4  col-4">
                  <h1
                    style={{
                      fontSize: "11px",
                      color: "rgba(38, 164, 219, 1)",
                      paddingBottom: "10px",
                    }}
                  >
                    Launch Date
                  </h1>
                  <p style={{ fontSize: "13px", color: "white" }}>{new Date(launchDate * 1000).toLocaleString()}</p>
                </div>


              </div>


            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Detail;
