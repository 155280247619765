// export const config = {
//     rpcUrl:"https://dimensional-dawn-star.matic-testnet.discover.quiknode.pro/2446dca845f74dac39f8a0d68d62844407f95566/",
//     networkID: "80001",
//     tokenBalanceDecimel: 1e18,
//     usdtTokenDecimel: 1e18,
//     networkName: "BSC",
//     currencyName: "BSC",
//     backendUrl: "http://localhost:2800",
//     currencyBalanceDecimel: 1e18, // (Binance,Matic,Ethereum)
//     usdtAddress: "0x4751aB2e8574510d179F0272a9906F6527117f7b",
//     launchPadAddress: "0x0C5C43e68e5FeA717486EA7844B9Ac8069be42D0",
//     //0xdC413a5C05DE650175D169c3D959Dc7290d2B684
//     emptyCurrentSaleContractAddres: "0x0000000000000000000000000000000000000000",
//     launchPadABI: [

//         "function totalPresale()  view returns(uint256)",
//         "function getPresaleDetail(uint256 _counter) view returns(address ,address ,string memory,uint256 ,uint256 ,uint256 ,uint256 ,uint256 ,uint256 ,uint256,uint256 )",
//         "function investWithUSDT(uint256 _count, uint256 _amount) returns(uint256)",
//         "function isPurchasedListed(address _addr) view returns (bool)",
//         "function getPresaleStatus(uint256 _count) view returns (bool)",
        

//     ]

// }



export const config = {
    rpcUrl:"https://bsc-dataseed.binance.org/",//"https://floral-skilled-theorem.bsc.discover.quiknode.pro/3b06b6cb8b17159fdb72f7d9db49051da17889f8/",
    networkID: "56",
    tokenBalanceDecimel: 1e18,
    usdtTokenDecimel: 1e18,
    networkName: "BSC",
    currencyName: "BSC",
    backendUrl: "http://localhost:2800",
    currencyBalanceDecimel: 1e18, // (Binance,Matic,Ethereum)
    usdtAddress: "0x55d398326f99059fF775485246999027B3197955",
    launchPadAddress: "0xEF9E1135f41387e89f6eADBeBBD3b1E73Fe02E57",
    //0xdC413a5C05DE650175D169c3D959Dc7290d2B684
    emptyCurrentSaleContractAddres: "0x0000000000000000000000000000000000000000",
    launchPadABI: [

        "function totalPresale()  view returns(uint256)",
        "function getPresaleDetail(uint256 _counter) view returns(address ,address ,string memory,uint256 ,uint256 ,uint256 ,uint256 ,uint256 ,uint256 ,uint256,uint256 )",
        "function investWithUSDT(uint256 _count, uint256 _amount) returns(uint256)",
        "function isPurchasedListed(address _addr) view returns (bool)",
        "function getPresaleStatus(uint256 _count) view returns (bool)",
        

    ]

}