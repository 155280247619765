import React from "react";
import TabsSearch from "./TabsSearch";


const Main = (props) => {
  console.log("prope",props.history)

  return (
    <div className="pt-2 pt-md-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
       
            <div className="">
              <TabsSearch history={props.history}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
