import { PROVIDER, SET_NETOWRK_ID, SET_WALLET, METAMASK_PROVIDER, METAMASK_CONNECTION, USER_WALLET_USDT_BALANCE, USER_WALLET_BNB_BALANCE } from "../types/WalletTypes"
import { getTokenBalance, getBNBBalance } from "../../blockchain"
import { config } from "../../config"



export const saveProvider = (provider) => {
    return {
        type: PROVIDER,
        payload: provider
    }
}
export const setNetworkID = (networkID) => {
    return {
        type: SET_NETOWRK_ID,
        payload: networkID
    }
}

export const setWallet = (wallet) => {
    return {
        type: SET_WALLET,
        payload: wallet
    }
}

export const saveMetamaskProvider = (provider) => {
    return {
        type: METAMASK_PROVIDER,
        payload: provider
    }
}
export const setMetamaskConnection = (value) => {
    return {
        type: METAMASK_CONNECTION,
        payload: value
    }
}



export const setUserWalletUsdtBalance = (usdtAddress, walletAddress, myProvider) => async (dispatch) => {

    try {
        const { balanceToken } = await getTokenBalance(usdtAddress, walletAddress, myProvider);
        console.log("balanceToken",balanceToken,usdtAddress,walletAddress)
        dispatch({
            type: USER_WALLET_USDT_BALANCE,
            payload: balanceToken,
        })

    } catch (err) {
        dispatch({
            type: USER_WALLET_USDT_BALANCE,
            payload: 0,
        })

    }
}


export const setUserWalletBNBBalance = (walletAddress, myProvider) => async (dispatch) => {

    try {
        const { balance, formateBalance } = await getBNBBalance(walletAddress, myProvider);

        console.log("BNB Balance",walletAddress,formateBalance , parseFloat(balance / config.currencyBalanceDecimel),parseFloat(balance)/config.currencyBalanceDecimel)
        dispatch({
            type: USER_WALLET_BNB_BALANCE,
            payload: parseFloat(balance / config.currencyBalanceDecimel),
        })

    } catch (err) {
        dispatch({
            type: USER_WALLET_BNB_BALANCE,
            payload: 0,
        })

    }
}




