import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Select from "react-select";
import FilledCard from "../utils/FilledCard";
import {
  setNetworkID,
  setWallet,
  saveMetamaskProvider,
  setMetamaskConnection,
} from "../redux/action/WalletAction";
import store from "../store";
import UpcommingCard from "../utils/UpcommingCard";
import LiveCard from "../utils/LiveCard";
import Countdown from "react-countdown";
import { useSelector } from "react-redux";
import { config } from "../config";
//import Countdown from 'countdown';
import { contractAddresses } from "../utils/ABI";
import LoadingOverlay from "../utils/LoadingOverlay";
import axios from "axios";
import "react-tabs/style/react-tabs.css";

import { ethers } from "ethers";

//import LoadingOverlay from 'react-loading-overlay';
import { isEmpty } from "lodash";
import Swal from "sweetalert";
import {
  tSale,
  connectMetamask,
  getPreSale,
  getTokenName,
  getTokenBalance,
  transactionApprove,
  investTxn,
  getContractBalance,
  connectMetamask1,
  checkAlreadyPurchase,
  getPresaleStatusValue,
} from "../blockchain";
const TabsSearch = (props) => {
  const [currentSaleIndex, setCurrentSaleIndex] = useState("");
  const [closeSale, setCloseSale] = useState([]);
  const [currentSaleObject, setCurrentSaleObject] = useState({});
  const [incomingSaleObject, setIncomingSaleObject] = useState({});
  const [title, setTitle] = useState("");
  const [des, setDesc] = useState("");
  const [twitter, setTwitter] = useState("");
  const [telegram, setTelegram] = useState("");
  const [website, setWebsite] = useState("");
  const [logo, setLogo] = useState("");
  const [incomingTitle, setIncomingTitle] = useState("");
  const [incomingDes, setIncomingDes] = useState("");
  const [incomingTwitter, setIncomingTwitter] = useState("");
  const [incomingTelegram, setIncomingTelegram] = useState("");
  const [incomingWebsite, setIncomingWebsite] = useState("");
  const [incomingLogo, setIncomingLogo] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [amountUSDTPay, setAmountUSDTPay] = useState(0);
  const [amountOfPurchaseToken, setAmountOfPurchaseToken] = useState(0);
  const [saleTokenUsdtPrice, setSaleTokenUsdtPrice] = useState(0);
  const [mainLoading, setMainLoading] = useState(true);
  const [myProvider, setMyProvider] = useState("");
  const [tokenBalance, setTokenBalance] = useState(0);
  const [tokenName, setTokenName] = useState("");
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [incomingTokenBalance, setIncomingTokenBalance] = useState(0);
  const [incomingTokenName, setIncomingTokenName] = useState("");
  const [incomingSaleTokenUsdtPrice, setIncomingSaleTokenUsdtPrice] =
    useState(0);
  const [incomingAmountUSDTPay, setIncomingAmountUSDTPay] = useState(0);
  const [incomingAmountOfPurchaseToken, setIncomingAmountOfPurchaseToken] =
    useState(0);
  const [saleEndTime, setSaleEndtime] = useState(0);
  const [saleEndTimeFormatted, setSaleEndtimeFormatted] = useState(0);
  const [saleStartTimeIncoming, setSaleStartTimeIncoming] = useState(0);
  const [saleStartTimeIncomingFormatted, setSaleStartTimeIncomingFormatted] =
    useState(0);
  const [timeRemaining, setTimeRemaining] = useState(0); //
  const [timeRemainingIncoming, setTimeRemainingIncoming] = useState(0); //
  const [tokenAddress, setTokenAddress] = useState("");
  const [contractBalance, setContractBalance] = useState("");
  const [showRemaing, setShowRemaing] = useState(true);
  const [launchPlateform, setLaunchPlateform] = useState("");
  const [launchDate, setLaunchDate] = useState("");
  const [currentTab, setCurrentTab] = useState(0);
  const [incomingLaunchPlateform, setIncomingLaunchPlateform] = useState("");
  const [incomingLaunchDate, setIncomingLaunchDate] = useState("");
  const [metaMaskProvider, setMetaMaskProvider] = useState("");
  const [tokenToUsdtPrice, setTokenToUsdtPrice] = useState(0);
  const [usdtBalance, setUsdtBalance] = useState(0);
  const [currencyBalance, setCurrencyBalance] = useState(0);
  const [youtubeUrl, setYoutubeUrl] = useState("");


  const walletReducer = useSelector((state) => state.WalletReducers);

  useEffect(() => {
    if (!isEmpty(walletReducer.provider)) {
      setMyProvider(walletReducer.provider);
      setMetaMaskProvider(walletReducer.metamaskProvider);
      setWalletAddress(walletReducer.connectedWallet);
      setUsdtBalance(walletReducer.userWalletUsdtBalance);
      setCurrencyBalance(walletReducer.userWalletBNBBalance);
    }

    if (!isEmpty(myProvider)) {
      getCurrenctSale();
    }
  }, [walletReducer, myProvider]);

  //get Current Sale
  let getCurrenctSale = async () => {
    try {
      setMainLoading(true);
      let currentSaleIndex = await tSale(myProvider);
      console.log("current sale ", currentSaleIndex.totalSale.toString())
      await setSaleData(currentSaleIndex.totalSale.toString());
      setMainLoading(false);
    } catch (e) {
      setMainLoading(false);
    }
  };

  //set sale data
  const setSaleData = async (currentSaleIndex) => {
    try {
      const presaleDetails = [];
      let currentSaleObject;
      let incomingSale;
      let liveSaleIndex;
      let incomingSaleIndex
      const currentTime = Date.now() / 1000; // Convert milliseconds to seconds

      for (let i = currentSaleIndex; i >= 0; i--) {
        let sale = await getPreSale(myProvider, i);
        console.log("current sale total", sale, i);

        // it is incoming sale
        if (currentTime < sale.preSale._startTime) {

          if (sale.preSale._tokenAddress === config.emptyCurrentSaleContractAddres) {
            console.log("preSaleValues main 2 inside")

          }
          else {
            incomingSaleIndex = i;
            incomingSale = sale;

          }

        }
        if (
          currentTime > sale.preSale._startTime &&
          currentTime < sale.preSale._endTime
        ) {
          if (sale.preSale._tokenAddress === config.emptyCurrentSaleContractAddres) {
            console.log("preSaleValues main 2 inside")

          }
          else {
            currentSaleObject = sale;
            liveSaleIndex = i;

          }

        }
        if (currentTime > sale.preSale._endTime) {


          if (sale.preSale._tokenAddress === config.emptyCurrentSaleContractAddres) {
            console.log("preSaleValues main 2 inside")

          }
          else {


            let prsaleSttus = await getPresaleStatusValue(myProvider, i);
            if (prsaleSttus === false) {

            }
            else {
              presaleDetails.push(sale);
            }






          }



        }
      }

      console.log("current sale currentSaleObject", currentSaleObject,"current sale live index", liveSaleIndex,"current sale  incomingSale", incomingSale,"incomingSale", presaleDetails);



      if (!isEmpty(currentSaleObject)) {
        // check if it is closed
        let prsaleSttus = await getPresaleStatusValue(myProvider, liveSaleIndex);
        console.log("prsaleSttus", prsaleSttus);
        if (prsaleSttus === false) {
        }

        else {
          // setCurrentTab("live")
          setSaleEndtime(currentSaleObject.preSale._endTime);
          setCurrentSaleObject(currentSaleObject);
          setCurrentSaleIndex(liveSaleIndex)

          // setIncomingSaleObject(currentSaleObject);
          //method call
          await setCurrentSaleObjectValue(currentSaleObject);
          let tokenName = await getErc20TokenName(currentSaleObject);
          if (walletAddress) {

            let tokenBalance = await getErc20TokenBalance(currentSaleObject);

            setTokenBalance(tokenBalance / config.tokenBalanceDecimel);
          }

          setTokenName(tokenName);
          setSaleTokenUsdtPrice(
            currentSaleObject.preSale._prelaunchprice / config.tokenBalanceDecimel
          );

          setTokenToUsdtPrice(
            1 /
            (currentSaleObject.preSale._prelaunchprice /
              config.tokenBalanceDecimel)
          );
        }
      }
      // Live sale end 
      //alert("live sale end reached")

      if (!isEmpty(incomingSale)) {
        //alert(incomingSaleIndex)
        // alert("inside")
        // alert("1")
        let prsaleSttus = await getPresaleStatusValue(myProvider, incomingSaleIndex);
        // alert("2")
        console.log("prsaleSttus", prsaleSttus);
        if (prsaleSttus === false) {
        }
        else {
          if (isEmpty(currentSaleObject)) {
            setCurrentTab(1);
          }
          // alert("1")
          console.log("Inci", incomingSale);
          setSaleStartTimeIncoming(incomingSale.preSale._startTime);
          setIncomingSaleObject(incomingSale);
          //method call
          await setIncomingSaleObjectValue(incomingSale);
          let tokenName = await getErc20TokenName(incomingSale);
          setIncomingTokenName(tokenName);
          setIncomingSaleTokenUsdtPrice(
            incomingSale.preSale._prelaunchprice / config.tokenBalanceDecimel
          );
        }


      }
      // alert("reached there")
      setCurrentSaleIndex(liveSaleIndex);
      setCloseSale(presaleDetails);
      return;
    }
    catch (e) {
      console.log("prsaleSttus", e)

    }
  };

  const setCurrentSaleObjectValue = async (saleObject) => {
    // let dataString = saleObject.preSale._imageurl
    let dataString = saleObject.preSale._imageurl;

    let dataArray = dataString.split(",");
    let title = dataArray.shift().trim();
    let email = dataArray.shift().trim();
    let launchDate = dataArray.shift().trim();
    let launchPlateform = dataArray.shift().trim();
    setTitle(title);
    setTelegram(email);
    setLaunchDate(launchDate);
    setLaunchPlateform(launchPlateform);

    let urlStartIndex = dataArray.findIndex(
      (item) =>
        item.trim().startsWith("http://") ||
        item.trim().startsWith("https://") ||
        item.trim().startsWith("www.")
    );
    // Extract the description
    let description = dataArray.slice(0, urlStartIndex).join(",").trim();
    // Extract the URLs and other fields
    let urlsAndFields = dataArray.slice(urlStartIndex);

    setDesc(description);
    //console.log("urlsAndFields",saleObject,dataString,urlsAndFields,launchPlateform,dataArray)

    setLogo(urlsAndFields[0].trim());
    setWebsite(urlsAndFields[1].trim());
    setTwitter(urlsAndFields[2].trim());

    return;
  };

  const setIncomingSaleObjectValue = async (saleObject) => {
    let dataString = saleObject.preSale._imageurl;
    //  let dataString = "HOTTEST UPCOMING SALE LIVE, We are ambassadors of peace in the SEC's war against cryptocurrency investors, exchanges and supporters. Meet ANTISEC Coin, the voice of all crypto investors, fighting against the SEC, which is disrupting the cryptocurrency market by filing lawsuits against Binance, CZ and Coinbase. We're with Binance. VERY HAPPY TO BE LIVE WITH YOU., https://img.freepik.com/free-psd/google-icon-isolated-3d-render-illustration_47987-9777.jpg?w=2000,https://www.instagram.com/, https://twitter.com/stabolut,https://telegram.org/";
    let dataArray = dataString.split(",");
    let title = dataArray.shift().trim();
    let email = dataArray.shift().trim();
    let launchDate = dataArray.shift().trim();
    let launchPlateform = dataArray.shift().trim();
    setIncomingLaunchDate(launchDate);
    setIncomingLaunchPlateform(launchPlateform);
    let urlStartIndex = dataArray.findIndex(
      (item) =>
        item.trim().startsWith("http://") ||
        item.trim().startsWith("https://") ||
        item.trim().startsWith("www.")
    );
    // Extract the description
    let description = dataArray.slice(0, urlStartIndex).join(",").trim();
    // Extract the URLs and other fields
    let urlsAndFields = dataArray.slice(urlStartIndex);
    setIncomingTitle(title);
    setIncomingDes(description);
    console.log("trim", urlsAndFields[0].trim());
    setIncomingLogo(urlsAndFields[0].trim());
    setIncomingTwitter(urlsAndFields[2].trim());
    setIncomingTelegram(email);
    setIncomingWebsite(urlsAndFields[1].trim());
    return;
  };

  useEffect(() => {
    let getStatusFromApi = async () => {
      try {
        let { data } = await axios.get(`${config.backendUrl}/status`);

        if (data.data.length > 0) {
          setShowRemaing(data.data[0].status);
        }
        setYoutubeUrl(data.data[0].youtubeUrl);
      } catch (e) { }
    };
    getStatusFromApi();
  }, []);

  const getErc20TokenName = async (saleObject) => {
    try {
      const { name } = await getTokenName(
        myProvider,
        saleObject.preSale._tokenAddress
      );
      return name;
    } catch (error) {
      console.error("final token name", error);
    }
  };
  const getErc20TokenBalance = async (saleObject) => {
    try {
      const { balanceToken } = await getTokenBalance(
        saleObject.preSale._tokenAddress,
        walletAddress,
        myProvider
      );

      return balanceToken;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const detectMetaMask = async () => {
    if (window.ethereum) {
      try {
        let { networkId, accounts, provider } = await connectMetamask1();
        return { networkId, accounts, provider };
      } catch (error) {
        console.error("Error connecting to MetaMask:", error);
      }
    } else {
      Swal(
        "Warning!",
        `MetaMask extension not found. Please install MetaMask for a better experience.`,
        "warning"
      );

      // alert('MetaMask extension not found. Please install MetaMask for a better experience.')
    }
  };

  // Function to switch to a different network.
  const switchToNetwork = async (provider) => {
    try {
      await provider.send("wallet_addEthereumChain", [
        {
          chainId: "0x13881", // Mumbai network chain ID
          chainName: "Mumbai",
          rpcUrls: ["https://rpc.ankr.com/polygon_mumbai/"], // Mumbai RPC URL
          nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18,
          },
          blockExplorerUrls: ["https://explorer-mumbai.maticvigil.com/"],
        },
      ]);
    } catch (switchError) {
      Swal("Warning!", `Error switching network:`, "warning");
      console.error("Error switching network:", switchError);
    }
  };

  let purchase = async () => {
    try {

      let data = await detectMetaMask();
      // console.log("my Data", data)
      if (data === undefined) return

      if (data.networkId.toString() !== config.networkID) {
        Swal(
          "Warning!",
          `Kindly establish a connection with  ${config.networkName} network in metamask for the purpose of purchasing coins.`,
          "warning"
        );
        return;
      }

      if (parseFloat(amountUSDTPay) === 0 || parseFloat(amountUSDTPay) < 0) {
        Swal("Warning!", `The USDT payment value must exceed 0.`, "warning");
        return;
      }

      setLoading(true);
      setDisable(true);
      //alert("reached there")

      // let alreadyPurcahse = await checkAlreadyPurchase(
      //   myProvider,
      //   walletAddress
      // );
      let balance = await getErc20TokenBalance(currentSaleObject);
      let finalBalance = balance / config.tokenBalanceDecimel
      // alert(typeof finalBalance)

      // console.log("alreadyPurcahse", alreadyPurcahse)
      if (finalBalance > 0) {
        Swal(
          "Warning!",
          `You've already made a purchase. Please wait for next presale.`,
          "warning"
        );
        setLoading(false);
        setDisable(false);
        return;
      }


      if (
        parseFloat(amountUSDTPay) >
        parseFloat(currentSaleObject.preSale._maximumInvestment) /
        config.tokenBalanceDecimel
      ) {
        Swal(
          "Warning!",
          `Maximum coin purchase limit is ${parseFloat(currentSaleObject.preSale._maximumInvestment) /
          config.tokenBalanceDecimel
          } usdt.`,
          "warning"
        );
        setLoading(false);
        setDisable(false);
        return;
      }
      if (
        parseFloat(amountUSDTPay) <
        parseFloat(currentSaleObject.preSale._minimumInvestment) /
        config.tokenBalanceDecimel
      ) {
        Swal(
          "Warning!",
          `Minimum coin purchase limit is ${parseFloat(currentSaleObject.preSale._minimumInvestment) /
          config.tokenBalanceDecimel
          } usdt.`,
          "warning"
        );
        setLoading(false);
        setDisable(false);
        return;
      }


      if (parseFloat(amountUSDTPay) >= parseFloat(usdtBalance)) {
        Swal(
          "Warning!",
          `You must top up your balance with an additional ${parseFloat(amountUSDTPay) - parseFloat(usdtBalance)
          } USDT before proceeding with the transaction, as your current balance is only ${parseFloat(
            usdtBalance
          )} USDT.`,
          "warning"
        );
        setLoading(false);
        setDisable(false);
        return;
      }

      if (parseFloat(currencyBalance) <= 0.005) {
        Swal(
          "Warning!",
          `To complete a transaction, you must have a minimum balance of 0.005 BNB in your account.`,
          "warning"
        );
        setLoading(false);
        setDisable(false);
        return;
      }



      let res = await transactionApprove(amountUSDTPay.toString(), metaMaskProvider);
      console.log("response abc", res)
      // alert(currentSaleIndex)
      await investTxn(amountUSDTPay, metaMaskProvider, currentSaleIndex);


      let tokenBalance = await getErc20TokenBalance(currentSaleObject);
      setTokenBalance(tokenBalance / config.tokenBalanceDecimel);
      Swal(
        "Congratulations!",
        "You have successfully purchased the tokens! Now you can Import this token in to your Metamask",
        "success"
      );
      setLoading(false);
      setDisable(false);
    } catch (e) {
      // console.log("Ererrr", e.response);
      if (e === "execution reverted: balance low") {
        console.log("Ererrr", "inside");
      }
      setLoading(false);
      setDisable(false);
      let message = e.message ? e.message : "Facing some issue in transaction";
      // console.log("message", message)
      Swal("Error!", JSON.stringify(message), "error");
    }
  };

  useEffect(() => {
    if (saleEndTime > 0) {
      const timestamp = saleEndTime; // The provided timestamp
      const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
      const formattedDate = date.toISOString(); // Convert date to the format "YYYY-MM-DDTHH:mm:ss.sssZ"
      const endTime = new Date(formattedDate).getTime();
      setSaleEndtimeFormatted(endTime);
    }
  }, [saleEndTime]);

  useEffect(() => {
    if (saleStartTimeIncoming > 0) {
      // Assuming saleStartTime is provided in seconds
      const timestamp = saleStartTimeIncoming;
      const date = new Date(timestamp * 1000);
      const formattedDate = date.toISOString();
      const startTime = new Date(formattedDate).getTime();
      setSaleStartTimeIncomingFormatted(startTime);
    }
  }, [saleStartTimeIncoming]);

  useEffect(() => {
    if (saleEndTimeFormatted > 0) {
      setTimeRemaining(
        localStorage.getItem("countdownTimeRemaining")
          ? parseInt(localStorage.getItem("countdownTimeRemaining"), 10)
          : saleEndTimeFormatted - Date.now()
      );
    }
  }, [saleEndTimeFormatted]);

  useEffect(() => {
    if (saleStartTimeIncomingFormatted > 0) {
      setTimeRemainingIncoming(
        localStorage.getItem("countdownTimeRemainingIncoming")
          ? parseInt(localStorage.getItem("countdownTimeRemaining"), 10)
          : saleStartTimeIncomingFormatted - Date.now()
      );
    }
  }, [saleStartTimeIncomingFormatted]);

  useEffect(() => {
    if (timeRemaining && saleEndTimeFormatted > 0) {
      const countdownInterval = setInterval(() => {
        setTimeRemaining((prevRemainingTime) => {
          const now = Date.now();
          const updatedRemainingTime = saleEndTimeFormatted - now;

          if (updatedRemainingTime <= 0) {
            clearInterval(countdownInterval);
            return 0;
          }

          localStorage.setItem(
            "countdownTimeRemaining",
            updatedRemainingTime.toString()
          );
          return updatedRemainingTime;
        });
      }, 1000);

      return () => {
        clearInterval(countdownInterval);
      };
    }
  }, [timeRemaining]);

  useEffect(() => {
    if (timeRemainingIncoming && saleStartTimeIncomingFormatted > 0) {
      const countdownInterval = setInterval(() => {
        setTimeRemainingIncoming((prevRemainingTime) => {
          const now = Date.now();
          const updatedRemainingTime = saleStartTimeIncomingFormatted - now;

          if (updatedRemainingTime <= 0) {
            clearInterval(countdownInterval);
            return 0;
          }
          localStorage.setItem(
            "countdownTimeRemainingIncoming",
            updatedRemainingTime.toString()
          );
          return updatedRemainingTime;
        });
      }, 1000);

      return () => {
        clearInterval(countdownInterval);
      };
    }
  }, [timeRemainingIncoming]);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render the remaining time after completion
      return (
        <div className="d-flex mt-3 countdown justify-content-center">
          <div className="timmer-m">
            <h1>0</h1>
            <span>Days</span>
          </div>
          <div className="timmer-m">
            <h1>0</h1>
            <span>Hours</span>
          </div>
          <div className="timmer-m">
            <h1>0</h1>
            <span>Minutes</span>
          </div>
          <div className="timmer-m">
            <h1>0</h1>
            <span>Seconds</span>
          </div>
        </div>
      );
    } else {
      // Render the countdown during active time
      return (
        <div className="d-flex mt-3  justify-content-center">
          <div className="timmer-m">
            <h1>{Math.floor(timeRemaining / (1000 * 60 * 60 * 24))}</h1>
            <span>Days</span>
          </div>
          <div className="timmer-m">
            <h1>
              {Math.floor(
                (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
              )}
            </h1>
            <span>Hours</span>
          </div>
          <div className="timmer-m">
            <h1>
              {Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60))}
            </h1>
            <span>Minutes</span>
          </div>
          <div className="timmer-m">
            <h1>{Math.floor((timeRemaining % (1000 * 60)) / 1000)}</h1>
            <span>Seconds</span>
          </div>
        </div>
      );
    }
  };

  const renderer1 = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Sale has started, render appropriate message or UI
      return (
        <div className="d-flex mt-3 countdown justify-content-center">
          <div className="timmer-m">
            <h1>Sale Started Refresh Page!</h1>
          </div>
        </div>
      );
    } else {
      // Render the countdown during active time
      return (
        <div className="d-flex mt-3  justify-content-center">
          <div className="timmer-m">
            <h1>{days}</h1>
            <span>Days</span>
          </div>
          <div className="timmer-m">
            <h1>{hours}</h1>
            <span>Hours</span>
          </div>
          <div className="timmer-m">
            <h1>{minutes}</h1>
            <span>Minutes</span>
          </div>
          <div className="timmer-m">
            <h1>{seconds}</h1>
            <span>Seconds</span>
          </div>
        </div>
      );
    }
  };

  const handleTabChange = (tabKey) => {
    // alert(tabKey)
    setCurrentTab(tabKey);
  };

  return (
    <div className="">
      {console.log("main", closeSale)}
      <Tabs
        selectedIndex={currentTab} // Use selectedIndex to specify the initially selected tab index
        onSelect={(index) => setCurrentTab(index)} // Use onSelect to handle tab selection
        id="uncontrolled-tab-example"
        className="mb-3 row custom-tab"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <TabList className="text-center">
          <Tab>Live Sale</Tab>
          <Tab>Upcoming</Tab>
          <Tab>Closed</Tab>
          <Tab>How To Purchase </Tab>
          {
            youtubeUrl && <Tab>How To Purchase</Tab>
          }

        </TabList>

        <TabPanel eventKey="live" title="Live Sale">
          {mainLoading === true ? (
            <div>
              <LoadingOverlay></LoadingOverlay>,
              <div style={{ height: "100vh" }}></div>
            </div>
          ) : isEmpty(currentSaleObject) ? (
            // <div
            //       style={{ justifyContent: "center", display: "flex" }}
            //       className="col-xl-12 col-md-6 mb-md-4"
            //     >

            // <div style={{
            //   color: "white", fontSize: 40, display: "flex", justifyContent: "center", alignItems: "center",
            //   marginBottom: 25,
            //   // top: '50%',

            //   fontWeight: "900",

            // }}>Currently, there are no incoming sales.</div></div>

            // <div
            //   style={{ justifyContent: "center", display: "flex" }}
            //   className="col-xl-12 col-md-6 mb-md-4"
            // >
            //   {/* <FilledCard /> */}
            //   <div
            //     style={{
            //       color: "white",
            //       fontSize: 40,
            //       display: "flex",
            //       justifyContent: "center",
            //       alignItems: "center",
            //       marginBottom: 25,
            //       // top: '50%',

            //       fontWeight: "900",
            //     }}
            //   >
            //     Currently, there are no live sale.
            //   </div>
            // </div>

            <div
              style={{ justifyContent: "center", display: "flex" }}
              className=" col-12"
            >
              {/* <FilledCard /> */}
              <div
                style={{
                  color: "white",
                  fontSize: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: 25,
                  // top: '50%',
                  height: "50vh",
                  fontWeight: "900",
                  textAlign: "center"
                }}
              >
                <h1> Currently, There are no "Live Sales"</h1>
                <h1> Check Later</h1>
              </div>
            </div>
          ) : (
            <>
              {/* <div className="row pb-3">
            <div className="col-lg-4">
              <LiveCard />
            </div>
            <div className="col-lg-4">
              <LiveCard />
            </div>
            <div className="col-lg-4">
              <LiveCard />
            </div>
         
          </div>
          <div className="row pb-3">
            <div className="col-lg-4">
              <LiveCard />
            </div>
            <div className="col-lg-4">
              <LiveCard />
            </div>
            <div className="col-lg-4">
              <LiveCard />
            </div>
           
          </div>
          <div className="row pb-3">
            <div className="col-lg-4">
              <LiveCard />
            </div>
            <div className="col-lg-4">
              <LiveCard />
            </div>
            <div className="col-lg-4">
              <LiveCard />
            </div>
          
          </div>
          <div className="row pb-3">
            <div className="col-lg-4">
              <LiveCard />
            </div>
            <div className="col-lg-4">
              <LiveCard />
            </div>
            <div className="col-lg-4">
              <LiveCard />
            </div>
           
          </div>
          <div className="row pb-3">
            <div className="col-lg-4">
              <LiveCard />
            </div>
            <div className="col-lg-4">
              <LiveCard />
            </div>
            <div className="col-lg-4">
              <LiveCard />
            </div>
           
          </div> */}
              <div className="row pb-5 pt-5 responsive">
                <div className="col-lg-7">
                  <div className="d-flex align-items-center">
                    <div>
                      <img
                        src={logo}
                        className="v-tag"
                        //alt="token-details"
                        style={{ marginRight: "10px", width: 94, height: 94 }}
                      />
                    </div>

                    <div>
                      <h1 className="text-white " style={{ fontSize: "25px" }}>
                        {title}
                      </h1>
                      <a target="_blank" href={website}>
                        <svg
                          width="26"
                          height="25"
                          viewBox="0 0 26 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: "20px" }}
                        >
                          <path
                            d="M13.2546 22.1036C19.0788 22.1036 23.8003 17.6098 23.8003 12.0665C23.8003 6.52309 19.0788 2.0293 13.2546 2.0293C7.43042 2.0293 2.70898 6.52309 2.70898 12.0665C2.70898 17.6098 7.43042 22.1036 13.2546 22.1036Z"
                            stroke="rgb(46, 160, 253)"
                            stroke-width="1.20446"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M2.70898 12.0645H23.8003"
                            stroke="rgb(46, 160, 253)"
                            stroke-width="1.20446"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M13.2554 2.0293C15.8931 4.77783 17.3922 8.34471 17.4736 12.0665C17.3922 15.7882 15.8931 19.3551 13.2554 22.1036C10.6176 19.3551 9.11857 15.7882 9.03711 12.0665C9.11857 8.34471 10.6176 4.77783 13.2554 2.0293Z"
                            stroke="rgb(46, 160, 253)"
                            stroke-width="1.20446"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </a>
                      <a target="_blank" href={twitter}>
                        <svg
                          width="26"
                          height="25"
                          viewBox="0 0 26 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: "20px" }}
                        >
                          <g clip-path="url(#clip0_2654_18191)">
                            <path
                              d="M24.4152 3.03158C23.4054 3.70957 22.2872 4.22812 21.1039 4.56726C20.4688 3.8722 19.6247 3.37956 18.6858 3.15596C17.747 2.93237 16.7586 2.98861 15.8544 3.31709C14.9502 3.64556 14.1738 4.23042 13.6302 4.99255C13.0866 5.75469 12.8021 6.65734 12.815 7.57841V8.58213C10.9618 8.62787 9.12541 8.23666 7.46947 7.44336C5.81352 6.65005 4.38941 5.47928 3.32397 4.03529C3.32397 4.03529 -0.894283 13.0687 8.59679 17.0836C6.42494 18.4868 3.83771 19.1903 1.21484 19.091C10.7059 24.1096 22.3061 19.091 22.3061 7.5483C22.3051 7.26872 22.2769 6.98983 22.2217 6.71522C23.298 5.70497 24.0576 4.42947 24.4152 3.03158Z"
                              stroke="rgb(46, 160, 253)"
                              stroke-width="1.20446"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2654_18191">
                              <rect
                                width="25.3095"
                                height="24.0892"
                                fill="rgb(46, 160, 253)"
                                transform="translate(0.15918 0.0214844)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                      <a target="_blank" href={`mailto:${telegram}`}>

                        <svg
                          width="27"
                          height="25"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 64 64"
                          id="email"
                        >
                          <path
                            fill="rgb(46, 160, 253)"
                            d="M53.42 53.32H10.58a8.51 8.51 0 0 1-8.5-8.5V19.18a8.51 8.51 0 0 1 8.5-8.5h42.84a8.51 8.51 0 0 1 8.5 8.5v25.64a8.51 8.51 0 0 1-8.5 8.5ZM10.58 13.68a5.5 5.5 0 0 0-5.5 5.5v25.64a5.5 5.5 0 0 0 5.5 5.5h42.84a5.5 5.5 0 0 0 5.5-5.5V19.18a5.5 5.5 0 0 0-5.5-5.5Z"
                          ></path>
                          <path
                            fill="rgb(46, 160, 253)"
                            d="M32 38.08a8.51 8.51 0 0 1-5.13-1.71L3.52 18.71a1.5 1.5 0 1 1 1.81-2.39L28.68 34a5.55 5.55 0 0 0 6.64 0l23.35-17.68a1.5 1.5 0 1 1 1.81 2.39L37.13 36.37A8.51 8.51 0 0 1 32 38.08Z"
                          ></path>
                          <path
                            fill="rgb(46, 160, 253)"
                            d="M4.17 49.14a1.5 1.5 0 0 1-1-2.62l18.4-16.41a1.5 1.5 0 0 1 2 2.24L5.17 48.76a1.46 1.46 0 0 1-1 .38zm55.66 0a1.46 1.46 0 0 1-1-.38l-18.4-16.41a1.5 1.5 0 1 1 2-2.24l18.39 16.41a1.5 1.5 0 0 1-1 2.62z"
                          ></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                  <p
                    className="text-align-justify pt-4 pb-4"
                    style={{
                      textAlign: "justify",
                      letterSpacing: "3%",
                      color: "white",
                      lineHeight: "43.47px",
                    }}
                  >
                    {des}
                  </p>
                </div>
                <div className="col-lg-5">
                  <div data-aos="fade-up" data-aos-duration="3000">
                    <div className="banner banner-sale text-center">
                      {/* <img src="assets/images/presell.png" className="w-100" /> */}
                      <div className="salecontent">
                        <div className="d-flex align-items-center mb-4 justify-content-center">
                          <div className="d-flex align-items-center mb-4 justify-content-center">
                            <h4 className="blueheadingtwo m-0">{tokenName} </h4>
                          </div>
                        </div>

                        <h4
                          className="whiteheadingtwo whiteheadingtwos mt-2"
                          style={{ fontSize: "21px" }}
                        >
                          Current Price:{" "}
                          {currentSaleObject &&
                            currentSaleObject.preSale &&
                            1 /
                            (parseFloat(
                              currentSaleObject.preSale._prelaunchprice
                            ) / config.tokenBalanceDecimel)}
                          {""}
                          <span style={{ fontSize: "21px" }}>USDT</span>
                        </h4>

                        <h4
                          className="whiteheadingtwo whiteheadingtwos mt-2 mb-4"
                          style={{ fontSize: "21px" }}
                        >
                          Launching Price:{" "}
                          {currentSaleObject &&
                            currentSaleObject.preSale &&
                            1 /
                            (parseFloat(currentSaleObject.preSale.price) /
                              config.tokenBalanceDecimel)}
                          {""}
                          <span style={{ fontSize: "21px" }}>USDT</span>
                        </h4>
                        <h3 className="whiteheadingtwo whiteheadingtwob m-3 ">
                          Presale End In
                        </h3>

                        {/* <div className="d-flex justify-content-between mb-4">
                        <div>sales start</div>
                        <div>
                          <Countdown
                            date={Date.now() + 555500000}
                          // renderer={renderer}
                          /></div>
                        <div>|</div>
                        <div>sales end</div>
                        <div>
                          <Countdown
                            date={Date.now() + 555500000}
                          // renderer={renderer}
                          />
                        </div>
                      </div> */}
                        {/* <Countdown
                      date={Date.now() + 555500000}
                      renderer={renderer}
                    /> */}
                        <Countdown
                          date={Date.now() + timeRemaining}
                          renderer={renderer}
                          autoStart={true}
                        />
                        {/* {timeRemaining} */}
                        {/* <span
                      style={{ fontSize: "16px", fontWeight: "500" }}
                      className="d-block mb-4 mt-4"
                    >
                      Listing price: $0.0000400
                    </span> */}
                        {/* <ul className="d-flex coins justify-content-center list-inline mt-4">
                      <li className="eth-list" style={{ width: "100px" }}>
                        ETH
                        <span>
                          <img src="assets/images/icons/et.png" alt="" />
                        </span>
                      </li>
                      <li className="eth-list" style={{ width: "112px" }}>
                        USDT
                        <span>
                          <img src="assets/images/icons/ustd.png" alt="" />
                        </span>
                      </li>
                      <li className="eth-list" style={{ width: "103px" }}>
                        BNB
                        <span>
                          <img src="assets/images/icons/bnb-b.png" alt="" />
                        </span>
                      </li>
                      <li className="eth-list" style={{ width: "117px" }}>
                        CARD
                        <span>
                          <img src="assets/images/icons/credit-card.png" alt="" />
                        </span>
                      </li>
                    </ul> */}
                        {/* <div class="progress" style={{ marginTop: "20px", marginBottom: "20px" }}> */}
                        {/* <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: "50%" }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                         {currentSaleObject && currentSaleObject.preSale && 
                         
                         
                         (parseFloat(contractBalance)/(parseFloat(currentSaleObject.preSale._remainingAmount/config.tokenBalanceDecimel)))*100}

                          
                        </div> */}
                        {/* {currentSaleObject && currentSaleObject.preSale &&

                          <div style={{ width: `${calculatePercentage()}%` }} class="progress-bar" >
                            <progress

                              value={calculatePercentage()} max={100} />
                            <span>{calculatePercentage()}%</span>

                          </div>
                        } */}

                        {/* {currentSaleObject && currentSaleObject.preSale &&

                          <div style={{ width: "90%" }} class="progress-bar" >
                            <progress

                              value={90} max={100} />
                            <span>{90}%</span>
 
                          </div>
                        } */}

                        {/* </div> */}

                        <div
                          className="d-flex justify-content-between mb-1 mt-3"
                          style={{
                            color: "#fff",
                            fontSize: "12px",
                            margin: "0px 5px 10px 5px",
                            fontWeight: "500",
                          }}
                        >
                          <div>Amount in USDT you pay</div>
                          <div>MAX</div>
                        </div>

                        <div
                          class="input-group flex-nowrap"
                          style={{
                            margin: "0px 5px 20px 5px",
                            paddingRight: "10px",
                          }}
                        >
                          <input
                            type="number"
                            onChange={(e) => {
                              //alert(saleTokenUsdtPrice)
                              setAmountUSDTPay(e.target.value);
                              setAmountOfPurchaseToken(
                                e.target.value * saleTokenUsdtPrice
                              );
                            }}
                            class="form-control"
                            placeholder="0"
                            aria-label=""
                            value={amountUSDTPay}
                            aria-describedby="addon-wrapping"
                            style={{ height: "50px", marginTop: "0px" }}
                          />
                          <span
                            class="input-group-text"
                            style={{ padding: "0px 20px" }}
                            id="addon-wrapping"
                          >
                            <img
                              className="m-tag"
                              style={{ width: "20px !imporant" }}
                              src="assets/images/icons/ustd.png"
                              alt=""
                            />
                          </span>
                        </div>

                        <div
                          className="d-flex justify-content-between"
                          style={{
                            color: "#fff",
                            fontSize: "12px",
                            margin: "0px 5px 4px 5px",
                            fontWeight: "500",
                          }}
                        >
                          <div>Amount in {tokenName} you received</div>
                          <div>
                            {tokenName} Balance:{tokenBalance.toFixed(4)}
                          </div>
                        </div>

                        <div
                          class="input-group flex-nowrap"
                          style={{
                            margin: "0px 5px 10px 5px",
                            paddingRight: "10px",
                          }}
                        >
                          <input
                            type="number"
                            class="form-control"
                            placeholder="0"
                            aria-label=""
                            //disabled={true}
                            onChange={(e) => {
                              setAmountOfPurchaseToken(e.target.value);
                              setAmountUSDTPay(
                                tokenToUsdtPrice * e.target.value
                              );
                            }}
                            value={amountOfPurchaseToken}
                            aria-describedby="addon-wrapping"
                            style={{ height: "50px", marginTop: "0px" }}
                          />
                          <span
                            class="input-group-text"
                            style={{ padding: "0px 20px" }}
                            id="addon-wrapping"
                          >
                            <img
                              style={{ width: 24, height: 25 }}
                              src={logo}
                              alt=""
                            />
                          </span>
                        </div>
                        {/* <span
                      className="d-block mt-4 mb-3"
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        marginLeft: "60px",
                        marginRight: "60px",
                      }}
                    >
                      0.020 ETH is reserved for gas. The actual amount used will
                      depend on the network.
                    </span> */}
                        <button
                          disabled={disable}
                          onClick={purchase}
                          className="btn mb-1 purchase-btn mt-3"
                        >
                          {loading === true ? (
                            <div style={{ display: "flex" }}>
                              <img
                                alt="loading..."
                                style={{

                                  width: "20px",
                                  marginRight: "8px",
                                }}
                                src="/assets/images/spinner3.gif"
                              ></img>
                              <p>Processing</p>

                            </div>
                          ) : (
                            " Purchase Tokens"
                          )}
                        </button>
                        {/* <span
                        className="d-block mt-4 mb-1"
                        style={{ fontSize: "12px", fontWeight: "500" }}
                      >
                        <img
                          src="assets/images/help.png"
                          className="me-2"
                          alt=""
                        />
                        How to buy
                      </span> */}
                      </div>
                      {/* <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    ETH{" "}
                    <span>
                      <img src="assets/images/et.png" alt="" />
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>select</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <div className="col-lg-12">
                  <div className="network-main">
                    <div className="d-flex justify-content-between align-items-center">
                      {/* <div><h2 style={{ color: "white" }}>Apply for Launchpad</h2></div> */}
                    </div>

                    <div className="inner-network mt-3">
                      <div className="row">
                        <div
                          className="col-lg-4 col-md-12"
                          style={{ position: "relative" }}
                        >
                          <div className="d-flex align-items-center net">
                            <img
                              style={{ width: "65px", marginRight: "10px" }}
                              src="assets/images/bnb.png"
                              alt="bsc"
                            />
                            <h2>Token Details</h2>
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-4">
                              <p>Maximum Buy</p>

                              <h4>
                                {currentSaleObject &&
                                  currentSaleObject.preSale &&
                                  parseFloat(
                                    currentSaleObject.preSale._maximumInvestment
                                  ) / config.tokenBalanceDecimel}{" "}
                                {"USDT"}
                              </h4>
                            </div>

                            <div className="col-lg-4 col-md-4 col-4">
                              <p>Minimum Buy</p>
                              <h4>
                                {currentSaleObject &&
                                  currentSaleObject.preSale &&
                                  parseFloat(
                                    currentSaleObject.preSale._minimumInvestment
                                  ) / config.tokenBalanceDecimel}{" "}
                                {"USDT"}
                              </h4>
                            </div>
                            {!isEmpty(currentSaleObject.preSale.amount) &&

                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Total Tokens in Presale </p>
                                <h4>

                                  {currentSaleObject &&
                                    currentSaleObject.preSale &&
                                    (
                                      parseFloat(
                                        currentSaleObject.preSale.amount
                                      ) / config.tokenBalanceDecimel
                                    ).toFixed(2)}{" "}
                                  {tokenName}
                                </h4>
                              </div>
                            }
                          </div>
                          <hr />
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Launch At</p>

                                <h4>
                                  {currentSaleObject &&
                                    currentSaleObject.preSale &&
                                    new Date(
                                      launchDate * 1000
                                    ).toLocaleString()}
                                </h4>
                              </div>
                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Launch On</p>
                                <div>
                                  <h4 style={{ wordBreak: "break-all" }}>
                                    <a target="_blank" href={launchPlateform}>
                                      {currentSaleObject &&
                                        currentSaleObject.preSale &&
                                        launchPlateform}
                                    </a>
                                  </h4>
                                </div>
                              </div>
                              {/* {showRemaing === true ? (
                                <div className="col-lg-4 col-md-4 col-4">
                                  <p>Remaining Tokens</p>
                                  <h4>
                                  
                                    {currentSaleObject &&
                                      currentSaleObject.preSale &&
                                      (
                                        parseFloat(
                                          currentSaleObject.preSale
                                            ._remainingAmount
                                        ) / config.tokenBalanceDecimel
                                      ).toFixed(2)}{" "}
                                    {tokenName}
                                  </h4>
                                </div>
                              ) : (
                                <div className="col-lg-4 col-md-4 col-4">
                                  <p>Remaining Amount</p>
                                  <h4>----------------------</h4>
                                </div>
                              )} */}

                              {/* {showRemaing === true ? (
                                <div className="col-lg-4 col-md-4 col-4">
                                  <p>Remaining Amount</p>
                                  <h4>
                                   
                                    {currentSaleObject &&
                                      currentSaleObject.preSale &&
                                      parseFloat(
                                        currentSaleObject.preSale
                                          ._remainingAmount
                                      ) / config.tokenBalanceDecimel}{" "}
                                    {tokenName}
                                  </h4>
                                </div>
                              ) : (
                                <div className="col-lg-4 col-md-4 col-4">
                                  <p>Remaining Amount</p>
                                  <h4>----------------------</h4>
                                </div>
                              )} */}
                              {/* <div className="col-lg-4 col-md-4 col-4">
                                    <p>Pre Launch Price</p>
                                    <h4 >
                                      {currentSaleObject && currentSaleObject.preSale && parseFloat(currentSaleObject.preSale._prelaunchprice) /
                                        config.tokenBalanceDecimel}{" "}USDT
                                    </h4>
                                  </div> */}

                              {/* <div className="col-lg-4 col-md-4 col-4">
                            <p>Max Investment</p>

                            <h4>
                              {currentSaleObject && currentSaleObject.preSale &&parseFloat(
                                currentSaleObject.preSale._maximumInvestment
                              ) / config.tokenBalanceDecimel}
                            </h4>
                          </div> 
                           <div className="col-lg-4 col-md-4 col-4">
                            <p>Max Supply</p>

                            <h4>
                              {currentSaleObject && currentSaleObject.preSale &&parseFloat(
                                currentSaleObject.preSale._minimumInvestment
                              ) / config.tokenBalanceDecimel}
                            </h4>
                          </div> 
                           <div className="col-lg-4 col-md-4 col-4">
                            <p>Pre Launch </p>
                            <h4>
                              {currentSaleObject && currentSaleObject.preSale &&parseFloat(currentSaleObject.preSale._prelaunchprice) /
                                config.tokenBalanceDecimel}{" "}
                              USDT
                            </h4>
                          </div>  */}
                            </div>
                          </div>

                          <hr />
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-12">
                                <p>Token Contract Address: <p style={{ fontSize: 20,wordBreak:"break-all" }}>{currentSaleObject.preSale._tokenAddress}</p></p>

                              </div>


                            </div>



                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </TabPanel>

        <TabPanel eventKey="upcoming" title="Upcoming">
          {mainLoading === true ? (
            <div>
              <LoadingOverlay></LoadingOverlay>,
              <div style={{ height: "100vh" }}></div>
            </div>
          ) : isEmpty(incomingSaleObject) ? (
            // <div
            //       style={{ justifyContent: "center", display: "flex" }}
            //       className="col-xl-12 col-md-6 mb-md-4"
            //     >

            // <div style={{
            //   color: "white", fontSize: 40, display: "flex", justifyContent: "center", alignItems: "center",
            //   marginBottom: 25,
            //   // top: '50%',

            //   fontWeight: "900",

            // }}>Currently, there are no incoming sales.</div></div>

            <div
              style={{ justifyContent: "center", display: "flex" }}
              className=" col-12"
            >
              {/* <FilledCard /> */}
              <div
                style={{
                  color: "white",
                  fontSize: 40,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 25,
                  // top: '50%',
                  height: "50vh",
                  fontWeight: "900",
                  textAlign: "center"
                }}
              >
                <h1> Currently, There are no "Upcoming Sales"</h1>
                <h1> Check Later</h1>
              </div>
            </div>
          ) : (
            <>
              <div className="row pb-5 pt-5 responsive">
                <div className="col-lg-7">
                  <div className="d-flex align-items-center">
                    <div>
                      <img
                        src={incomingLogo}
                        alt="token-details"
                        className="v-tag"
                        style={{ marginRight: "10px", width: 99, height: 94 }}
                      />
                    </div>

                    <div>
                      <h1 className="text-white " style={{ fontSize: "25px" }}>
                        {incomingTitle}
                      </h1>
                      <a target="_blank" href={incomingWebsite}>
                        <svg
                          width="26"
                          height="25"
                          viewBox="0 0 26 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: "20px" }}
                        >
                          <path
                            d="M13.2546 22.1036C19.0788 22.1036 23.8003 17.6098 23.8003 12.0665C23.8003 6.52309 19.0788 2.0293 13.2546 2.0293C7.43042 2.0293 2.70898 6.52309 2.70898 12.0665C2.70898 17.6098 7.43042 22.1036 13.2546 22.1036Z"
                            stroke="rgb(46, 160, 253)"
                            stroke-width="1.20446"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M2.70898 12.0645H23.8003"
                            stroke="rgb(46, 160, 253)"
                            stroke-width="1.20446"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M13.2554 2.0293C15.8931 4.77783 17.3922 8.34471 17.4736 12.0665C17.3922 15.7882 15.8931 19.3551 13.2554 22.1036C10.6176 19.3551 9.11857 15.7882 9.03711 12.0665C9.11857 8.34471 10.6176 4.77783 13.2554 2.0293Z"
                            stroke="rgb(46, 160, 253)"
                            stroke-width="1.20446"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </a>
                      <a target="_blank" href={incomingTwitter}>
                        <svg
                          width="26"
                          height="25"
                          viewBox="0 0 26 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: "20px" }}
                        >
                          <g clip-path="url(#clip0_2654_18191)">
                            <path
                              d="M24.4152 3.03158C23.4054 3.70957 22.2872 4.22812 21.1039 4.56726C20.4688 3.8722 19.6247 3.37956 18.6858 3.15596C17.747 2.93237 16.7586 2.98861 15.8544 3.31709C14.9502 3.64556 14.1738 4.23042 13.6302 4.99255C13.0866 5.75469 12.8021 6.65734 12.815 7.57841V8.58213C10.9618 8.62787 9.12541 8.23666 7.46947 7.44336C5.81352 6.65005 4.38941 5.47928 3.32397 4.03529C3.32397 4.03529 -0.894283 13.0687 8.59679 17.0836C6.42494 18.4868 3.83771 19.1903 1.21484 19.091C10.7059 24.1096 22.3061 19.091 22.3061 7.5483C22.3051 7.26872 22.2769 6.98983 22.2217 6.71522C23.298 5.70497 24.0576 4.42947 24.4152 3.03158Z"
                              stroke="rgb(46, 160, 253)"
                              stroke-width="1.20446"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2654_18191">
                              <rect
                                width="25.3095"
                                height="24.0892"
                                fill="white"
                                transform="translate(0.15918 0.0214844)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                      <a target="_blank" href={`mailto:${telegram}`}>


                        <svg
                          width="27"
                          height="25"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 64 64"
                          id="email"
                        >
                          <path
                            fill="rgb(46, 160, 253)"
                            d="M53.42 53.32H10.58a8.51 8.51 0 0 1-8.5-8.5V19.18a8.51 8.51 0 0 1 8.5-8.5h42.84a8.51 8.51 0 0 1 8.5 8.5v25.64a8.51 8.51 0 0 1-8.5 8.5ZM10.58 13.68a5.5 5.5 0 0 0-5.5 5.5v25.64a5.5 5.5 0 0 0 5.5 5.5h42.84a5.5 5.5 0 0 0 5.5-5.5V19.18a5.5 5.5 0 0 0-5.5-5.5Z"
                          ></path>
                          <path
                            fill="rgb(46, 160, 253)"
                            d="M32 38.08a8.51 8.51 0 0 1-5.13-1.71L3.52 18.71a1.5 1.5 0 1 1 1.81-2.39L28.68 34a5.55 5.55 0 0 0 6.64 0l23.35-17.68a1.5 1.5 0 1 1 1.81 2.39L37.13 36.37A8.51 8.51 0 0 1 32 38.08Z"
                          ></path>
                          <path
                            fill="rgb(46, 160, 253)"
                            d="M4.17 49.14a1.5 1.5 0 0 1-1-2.62l18.4-16.41a1.5 1.5 0 0 1 2 2.24L5.17 48.76a1.46 1.46 0 0 1-1 .38zm55.66 0a1.46 1.46 0 0 1-1-.38l-18.4-16.41a1.5 1.5 0 1 1 2-2.24l18.39 16.41a1.5 1.5 0 0 1-1 2.62z"
                          ></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                  <p
                    className="text-align-justify pt-4 pb-4"
                    style={{
                      textAlign: "justify",
                      letterSpacing: "3%",
                      color: "white",
                      lineHeight: "43.47px",
                    }}
                  >
                    {incomingDes}
                  </p>
                </div>
                <div style={{ marginTop: 64 }} className="col-lg-5 mt-10">
                  <div data-aos="fade-up" data-aos-duration="3000">
                    <div className="banner banner-sale text-center">
                      {/* <img src="assets/images/presell.png" className="w-100" /> */}
                      <div className="salecontent">
                        <div className="d-flex align-items-center mb-4 justify-content-center">
                          <h4 className="blueheadingtwo m-0">
                            {incomingTokenName}{" "}
                          </h4>
                        </div>
                        {/* <h3 className="whiteheadingtwo whiteheadingtwob m-3">
                        Presale End In
                      </h3> */}
                        {/* <h4
                          className="whiteheadingtwo whiteheadingtwos mt-2"
                          style={{ fontSize: "20px" }}
                        >
                          Pre-Sale Price 1 {incomingTokenName} = ${incomingSaleTokenUsdtPrice}
                        </h4> */}

                        <h4
                          className="whiteheadingtwo whiteheadingtwos mt-2"
                          style={{ fontSize: "21px" }}
                        >
                          Current Price:{" "}
                          {incomingSaleObject &&
                            incomingSaleObject.preSale &&
                            1 /
                            (parseFloat(
                              incomingSaleObject.preSale._prelaunchprice
                            ) / config.tokenBalanceDecimel)}
                          {""}
                          <span style={{ fontSize: "21px" }}>USDT</span>
                        </h4>

                        <h4
                          className="whiteheadingtwo whiteheadingtwos mt-2 mb-4"
                          style={{ fontSize: "21px" }}
                        >
                          Launching Price:{" "}
                          {incomingSaleObject &&
                            incomingSaleObject.preSale &&
                            1 /
                            (parseFloat(incomingSaleObject.preSale.price) /
                              config.tokenBalanceDecimel)}
                          {""}
                          <span style={{ fontSize: "21px" }}>USDT</span>
                        </h4>
                        <div>
                          <h3 className="whiteheadingtwo whiteheadingtwob m-3 ">
                            Sale Started At!
                          </h3>

                          <Countdown
                            date={Date.now() + timeRemainingIncoming}
                            renderer={renderer1}
                            autoStart={true}
                          ></Countdown>
                          {/* <h4
                            className="whiteheadingtwo whiteheadingtwos mt-4"
                            style={{ fontSize: "16px" }}
                          >
                            Sale Started At:{" "}
                            {incomingSaleObject &&
                              incomingSaleObject.preSale &&
                              new Date(
                                incomingSaleObject.preSale._startTime * 1000
                              ).toLocaleString()}
                            {""}
                          </h4>

                          <h4
                            className="whiteheadingtwo whiteheadingtwos mt-2 mb-4"
                            style={{ fontSize: "16px" }}
                          >
                            Sale Ended At:{" "}
                            {incomingSaleObject &&
                              incomingSaleObject.preSale &&
                              new Date(
                                incomingSaleObject.preSale._endTime * 1000
                              ).toLocaleString()}
                            {""}
                          </h4> */}
                        </div>

                        {/* <Countdown
                      date={Date.now() + 555500000}
                      renderer={renderer}
                    /> */}
                        {/* <Countdown
                      date={Date.now() + timeRemaining}
                      renderer={renderer}
                      autoStart={true}
                    /> */}
                        {/* {timeRemaining} */}
                        {/* <span
                      style={{ fontSize: "16px", fontWeight: "500" }}
                      className="d-block mb-4 mt-4"
                    >
                      Listing price: $0.0000400
                    </span> */}
                        {/* <ul className="d-flex coins justify-content-center list-inline mt-4">
                      <li className="eth-list" style={{ width: "100px" }}>
                        ETH
                        <span>
                          <img src="assets/images/icons/et.png" alt="" />
                        </span>
                      </li>
                      <li className="eth-list" style={{ width: "112px" }}>
                        USDT
                        <span>
                          <img src="assets/images/icons/ustd.png" alt="" />
                        </span>
                      </li>
                      <li className="eth-list" style={{ width: "103px" }}>
                        BNB
                        <span>
                          <img src="assets/images/icons/bnb-b.png" alt="" />
                        </span>
                      </li>
                      <li className="eth-list" style={{ width: "117px" }}>
                        CARD
                        <span>
                          <img src="assets/images/icons/credit-card.png" alt="" />
                        </span>
                      </li>
                    </ul> */}
                        {/* <div class="progress" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: "50%" }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          50%
                        </div>
                      </div> */}
                        {/* <div
                        className="d-flex justify-content-between mb-1"
                        style={{
                          color: "#fff",
                          fontSize: "12px",
                          margin: "0px 5px 10px 5px",
                          fontWeight: "500",
                        }}
                      >
                        <div>Amount in USDT you pay</div>
                        <div>MAX</div>
                      </div>

                      <div
                        class="input-group flex-nowrap"
                        style={{
                          margin: "0px 5px 20px 5px",
                          paddingRight: "10px",
                        }}
                      >
                        <input
                          type="number"
                          onChange={(e) => {
                            setIncomingAmountUSDTPay(e.target.value);
                            setIncomingAmountOfPurchaseToken(e.target.value / incomingSaleTokenUsdtPrice);
                          }}
                          class="form-control"
                          placeholder="0"
                          aria-label=""
                          aria-describedby="addon-wrapping"
                          style={{ height: "50px", marginTop: "0px" }}
                        />
                        <span
                          class="input-group-text"
                          style={{ padding: "0px 20px" }}
                          id="addon-wrapping"
                        >
                          <img src="assets/images/icons/et.png" alt="" />
                        </span>
                      </div> */}

                        {/* <div
                        className="d-flex justify-content-between"
                        style={{
                          color: "#fff",
                          fontSize: "12px",
                          margin: "0px 5px 4px 5px",
                          fontWeight: "500",
                        }}
                      >
                        <div>Amount in {incomingTokenName} you received</div>
                        <div>
                          {incomingTokenName} Balance:{incomingTokenBalance}
                        </div>
                      </div>

                      <div
                        class="input-group flex-nowrap"
                        style={{
                          margin: "0px 5px 10px 5px",
                          paddingRight: "10px",
                        }}
                      >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="0"
                          aria-label=""
                          disabled={true}
                          value={incomingAmountOfPurchaseToken}
                          aria-describedby="addon-wrapping"
                          style={{ height: "50px", marginTop: "0px" }}
                        />
                        <span
                          class="input-group-text"
                          style={{ padding: "0px 20px" }}
                          id="addon-wrapping"
                        >
                          <img style={{ width: 24, height: 25 }} src={logo} alt="" />
                        </span>
                      </div> */}
                        {/* <span
                      className="d-block mt-4 mb-3"
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        marginLeft: "60px",
                        marginRight: "60px",
                      }}
                    >
                      0.020 ETH is reserved for gas. The actual amount used will
                      depend on the network.
                    </span> */}
                      </div>
                      {/* <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    ETH{" "}
                    <span>
                      <img src="assets/images/et.png" alt="" />
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>select</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <div className="col-lg-12">
                  <div className="network-main">
                    <div className="d-flex justify-content-between align-items-center">
                      {/* <div><h2 style={{ color: "white" }}>Apply for Launchpad</h2></div> */}
                    </div>

                    <div className="inner-network mt-3">
                      <div className="row">
                        <div
                          className="col-lg-4 col-md-12"
                          style={{ position: "relative" }}
                        >
                          <div className="d-flex align-items-center net">
                            <img
                              style={{ width: "65px", marginRight: "10px" }}
                              src="assets/images/bnb.png"
                              alt="bsc"
                            />
                            <h2>Token Details</h2>
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-4">
                              <p>Maximum Buy</p>
                              <h4>
                                {incomingSaleObject &&
                                  incomingSaleObject.preSale &&
                                  parseFloat(
                                    incomingSaleObject.preSale
                                      ._maximumInvestment
                                  ) / config.tokenBalanceDecimel}{" "}
                                {"USDT"}
                              </h4>
                            </div>

                            <div className="col-lg-4 col-md-4 col-4">
                              <p>Minimum Buy</p>
                              <h4>
                                {incomingSaleObject &&
                                  incomingSaleObject.preSale &&
                                  parseFloat(
                                    incomingSaleObject.preSale
                                      ._minimumInvestment
                                  ) / config.tokenBalanceDecimel}{" "}
                                {"USDT"}
                              </h4>
                            </div>

                            {!isEmpty(incomingSaleObject.preSale.amount) &&

                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Total Tokens in Presale </p>
                                <h4>



                                  {incomingSaleObject &&
                                    incomingSaleObject.preSale &&
                                    (
                                      parseFloat(
                                        incomingSaleObject.preSale.amount
                                      ) / config.tokenBalanceDecimel
                                    ).toFixed(2)}{" "}
                                  {tokenName}





                                </h4>
                              </div>
                            }











                          </div>
                          <hr />
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Launch At</p>

                                <h4>
                                  {incomingSaleObject &&
                                    incomingSaleObject.preSale &&
                                    new Date(
                                      incomingLaunchDate * 1000
                                    ).toLocaleString()}
                                </h4>
                              </div>

                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Launch On</p>
                                <div>
                                  <h4>
                                    <a
                                      target="_blank"
                                      href={incomingLaunchPlateform}
                                    >
                                      {incomingSaleObject &&
                                        incomingSaleObject.preSale &&
                                        incomingLaunchPlateform}
                                    </a>
                                  </h4>
                                </div>
                              </div>
                              {/* {showRemaing === true ? (
                                <div className="col-lg-4 col-md-4 col-4">
                                  <p>Remaining Tokens</p>
                                  <h4>
                                    {incomingSaleObject &&
                                      incomingSaleObject.preSale &&
                                      parseFloat(
                                        incomingSaleObject.preSale
                                          ._remainingAmount
                                      ) / config.tokenBalanceDecimel}{" "}
                                    {incomingTokenName}
                                  </h4>
                                </div>
                              ) : (
                                <div className="col-lg-4 col-md-4 col-4">
                                  <p>Remaining Amount</p>
                                  <h4>----------------------</h4>
                                </div>
                              )} */}


                            </div>
                          </div>

                          <hr />
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-12">
                                <p>Token Contract Address: <p style={{ fontSize: 20,wordBreak:"break-all" }}>{incomingSaleObject.preSale._tokenAddress}</p></p>

                              </div>


                            </div>



                          </div>




                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </TabPanel>
        <TabPanel eventKey="filled" title="Closed">
          <div className="row pt-5">
            {closeSale.length > 0 ? (
              closeSale.map((item, index) => (
                <div className="col-xl-3 col-md-6 mb-md-4 mb-3">
                  <FilledCard provider={myProvider} item={item} />
                </div>
              ))
            ) : (
              <div
                style={{ justifyContent: "center", display: "flex" }}
                className=" col-12"
              >
                {/* <FilledCard /> */}
                <div
                  style={{
                    color: "white",
                    fontSize: 40,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 25,
                    flexDirection: "column",
                    // top: '50%',
                    height: "50vh",
                    fontWeight: "900",
                    textAlign: "center"
                  }}
                >
                  <h1> Currently, There are no "Closed Sales"</h1>
                  <h1> Check Later</h1>
                </div>
              </div>
            )}
          </div>
        </TabPanel>



        {/* {
          youtubeUrl && */}
          
          <TabPanel eventKey="filled" title="Info">
            <div className="row pt-5">
              <div style={{ padding: 16 }}>
                {/* <iframe
  width="560"
  height="315"
  src={config.youtube_url}
  frameborder="0"
  allowfullscreen
></iframe> */}

                {/* <iframe width="100%" height="600" src={"https://youtu.be/CiaV-2k3pFI?si=UpuWOYfDKGSXF2Sh"} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
             
                <iframe src="https://player.vimeo.com/video/878335820?h=694b59089a" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

             
              </div>
            </div>
          </TabPanel>

        {/* } */}












        {/* <Tab eventKey="home" title="All launchpads">
          <div className="row pt-3 pb-3">
            <div className="col-lg-4">
              <FilledCard />
            </div>
            <div className="col-lg-4">
              <UpcommingCard />
            </div>
            <div className="col-lg-4">
              <LiveCard />
            </div>
          </div>
          <div className="row pt-3 pb-3">
            <div className="col-lg-4">
              <FilledCard />
            </div>
            <div className="col-lg-4">
              <UpcommingCard />
            </div>
            <div className="col-lg-4">
              <LiveCard />
            </div>
          </div>
          <div className="row pt-3 pb-3">
            <div className="col-lg-4">
              <FilledCard />
            </div>
            <div className="col-lg-4">
              <UpcommingCard />
            </div>
            <div className="col-lg-4">
              <LiveCard />
            </div>
          </div>
        </Tab> */}
      </Tabs>
      {!mainLoading && (
        <div className="d-flex justify-content-center mb-5 ">
          <a
            target="_blank"
            href="https://docs.google.com/forms/d/1rI6du_ea0MCv9q7VlL4YgQHCJVYdt9XLt4IYIJJ-Qkg/viewform?edit_requested=true"
            class="btn purchase-btn"
          >
            {" "}
            Apply for Launchpad
          </a>
        </div>
      )}
    </div>
  );
};

export default TabsSearch;
