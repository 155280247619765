import { Contract, ethers, BigNumber } from "ethers";
import { erc20ABI, launchPadABI, contractAddresses } from "./utils/ABI";
import { config } from "./config";



export const connectMetamask = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  await provider.send("eth_requestAccounts", []);
  const accounts = await provider.listAccounts();

  return { provider, accounts };
};


export const connectMetamask1 = async () => {
  const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
  const networkId = await window.ethereum.request({ method: 'net_version' });
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  // await provider.send("eth_requestAccounts", []);
  // const accounts = await provider.listAccounts();

  return { provider, accounts, networkId };
};

export const getProvider = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);


  return provider;
};

export const getBNBBalance = async (walletAddress, provider) => {
  const balance = await provider.getBalance(walletAddress);
  const formateBalance = ethers.utils.formatEther(balance);
  return { formateBalance, balance };
};

export const getTokenBalance = async (
  tokenAddress,
  walletAddress,
  provider
) => {
  const tokenContract = new ethers.Contract(tokenAddress, erc20ABI, provider);
  const balance = await tokenContract.balanceOf(walletAddress);
  const balanceToken = balance.toString(); //ethers.utils.formatUnits(balance.toString(), 6);

  return { balanceToken };
};

export const getTokenName = async (provider, tokenAddress) => {

  const tokenContract = new ethers.Contract(tokenAddress, erc20ABI, provider);
  const name = await tokenContract.name();

  return { name };
};

export const getSymbol = async (provider, tokenAddress) => {
  const tokenContract = new ethers.Contract(tokenAddress, erc20ABI, provider);
  const symbol = await tokenContract.symbol();
  return { symbol };
};

// launchpad contract methods //

export const getPreSale = async (provider, _counter) => {
  const { launchPadAddress } = contractAddresses;
  const launchPadContract = new ethers.Contract(
    config.launchPadAddress,
    config.launchPadABI,
    provider
  );

  const preSaleKeys = [
    '_tokenAddress',
    '_seller',
    '_imageurl',
    '_prelaunchprice',
    '_startTime',
    '_endTime',
    '_minimumInvestment',
    '_maximumInvestment',
    '_remainingAmount',
    'price',
    'amount'
  ];


  const preSaleValues = await launchPadContract.getPresaleDetail(_counter);
  console.log("preSaleValues", preSaleValues)


  // Convert BigNumber values and combine with keys
  const convertedPreSale = preSaleValues.map((value, index) => {
    let convertedValue;

    convertedValue = value.toString();

    return [preSaleKeys[index], convertedValue];
  });

  const preSale = Object.fromEntries(convertedPreSale);



  return { preSale };

};

export const tSale = async (provider) => {
  try {
    // const { launchPadAddress } = contractAddresses;

    const launchPadContract = new ethers.Contract(
      config.launchPadAddress,
      config.launchPadABI,
      provider
    );
    const totalSale = await launchPadContract.totalPresale();
    return { totalSale };
  }
  catch (e) {
    throw e.message
  }


};

export const transactionApprove = async (value, provider) => {
  console.log("Value in approve" , value, typeof value)
  try{
  
  const { usdtAddress, launchPadAddress } = contractAddresses;
  // console.log("final", usdtAddress, launchPadAddress, value)

  const signer = provider.getSigner();
  const usdtContract = new ethers.Contract(config.usdtAddress, erc20ABI, provider);

  const daiWithSigner = usdtContract.connect(signer);

  const usdt = ethers.utils.parseUnits(value, 18);
  console.log("Value in approve",usdt.toString())
  let transaction = await daiWithSigner.approve(
    config.launchPadAddress,
    usdt.toString()
  );

  await transaction.wait();

  return transaction.hash;
  }
  catch(e){
    throw e
    console.log("revert issue",e,e.message,e.response)
  }};

export const investTxn = async (value, provider, tokeIndex) => {



  let val = (value * 1e18);
  console.log("val", val, typeof val, tokeIndex);
  const signer = provider.getSigner();
  const launchpadContract = new ethers.Contract(
    config.launchPadAddress,
    config.launchPadABI,
    provider
  );

  const launchPadWithSigner = launchpadContract.connect(signer);
  console.log("config.launchPadAddress", config.launchPadAddress)
//12000000000000000000

  try {
    console.log("Value", val, tokeIndex)
    let tx = await launchPadWithSigner.investWithUSDT(tokeIndex, val.toString());

    let c = await tx.wait();
    console.log("c", c)
    const launchPadTransactionHash = tx.hash;
    return launchPadTransactionHash;
  } catch (e) {
    console.log("response abc", e)
    let msg = e.data ? e.data.message : e.message ? e.message : "Experiencing an internal issue at the moment. Kindly consider trying again later. Thank you for your understanding."
    throw msg

  }
};

export const getContractBalance = async (
  contractToken,
  provider
) => {
  const tokenContract = new ethers.Contract(contractToken, erc20ABI, provider);
  const balance = await tokenContract.balanceOf(config.launchPadAddress);
  const balanceToken = balance.toString(); //ethers.utils.formatUnits(balance.toString(), 6);

  return { balanceToken };
};



export const checkAlreadyPurchase = async (provider, walletAddress) => {

  try {
    // const { launchPadAddress } = contractAddresses;

    const launchPadContract = new ethers.Contract(
      config.launchPadAddress,
      config.launchPadABI,
      provider
    );
    const isPurcahse = await launchPadContract.isPurchasedListed(walletAddress);
    return isPurcahse
  }
  catch (e) {
    console.log("Errror", e)
    throw e.message
  }
};


export const getPresaleStatusValue = async (provider, index) => {



  try {
    // const { launchPadAddress } = contractAddresses;

    const launchPadContract = new ethers.Contract(
      config.launchPadAddress,
      config.launchPadABI,
      provider
    );
    const status = await launchPadContract.getPresaleStatus(index);
    return status
  }
  catch (e) {
    console.log("Errror", e)
    throw e.message
  }




};


